import { httpClient } from '../../../../helpers/httpClient';
import { PaginatedData } from '../../../../types/PaginatedData';
import queryString from 'query-string';
import { BudgetItem, CreateBudgetItem } from '../../types/BudgetItem';

const authClient = httpClient();

export const getBudgetItems = async (
  { page, limit, budgetId }: { page: number; limit: number; budgetId: number }
) => {
  const params = queryString.stringify({ page, limit }, { arrayFormat: 'bracket' });
  const response = await authClient().get<PaginatedData<BudgetItem>>(`/budget/${budgetId}/item?${params}`);
  return response.data;
};

export const createBudgetItem = async (
  data: CreateBudgetItem,
  budgetId: number,
) => {
  const response = await authClient().post<BudgetItem>(`/budget/${budgetId}/item`, data);
  return response.data;
};

export const deleteBudgetItem = async (
  budgetId: number,
  budgetItemId: number,
) => {
  await authClient().delete(`/budget/${budgetId}/item/${budgetItemId}`);
};