import { Col, Layout, Row, Space } from 'antd';
import Typography from 'antd/lib/typography';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { SideMenu } from '../../components/SideMenu';
import { useCurrentPage } from '../../hooks';
import { createSetupMenuItems } from './helpers';

export const SetupPage: React.FC = () => {
  const { currentPage, subdomain, navigate } = useCurrentPage();

  const onSetCurrentPage = (value: string) => {
    navigate(value);
  };

  const menuItems = createSetupMenuItems({ subdomain });

  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Layout.Content>
          <Row>
            <Col sm={5} style={{ backgroundColor: '#FFF', height: "100vh" }}>
              <Space.Compact direction="vertical">
                <Typography.Title level={2} style={{ marginTop: 0, padding: '10px 0 0 10px', marginBottom: 0 }}>
                  Setup da empresa
                </Typography.Title>
                <Typography.Text style={{ marginTop: 0, padding: '10px 0 0 10px', marginBottom: '10px' }} type="secondary">
                  Configure todas as informações e dados referentes à sua empresa aqui
                </Typography.Text>
                <SideMenu
                  setCurrentPage={onSetCurrentPage}
                  currentPage={currentPage}
                  items={menuItems} 
                />
              </Space.Compact>
            </Col>
            <Col sm={19} >
              <Outlet />
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </>
  );
};
