import React from 'react';
import { Button, Form, Input, InputNumber } from 'antd';
import { BudgetItemStore } from '../store';
import { CreateBudgetItem } from '../types/BudgetItem';
import { SelectProduct } from '../../Product/Product/components/SelectProduct';
import { Product } from '../../Product/Product/types/Product';

type Props = {
  isLoading: boolean;
  createBudgetItem: BudgetItemStore['createBudgetItem'];
}

export const BudgetItemsForm: React.FC<Props> = ({ isLoading, createBudgetItem }) => {
  const [form] = Form.useForm<CreateBudgetItem>();

  const onFinish = (item: CreateBudgetItem) => {
    createBudgetItem({ ...item, quantity: String(item.quantity) });
  };

  const onSelectProduct = (product: Product) => {
    form.setFieldValue('productId', product.id);
    form.setFieldValue('metricUnit', product.metricUnit);
    form.validateFields();
    form.getFieldInstance('quantity').select()
  }
  
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      requiredMark={false}
    >
      <Form.Item
        label="Produto"
        rules={[{ required: true, message: 'Campo obrigatório' }]}
        status="error"
        name="productId"
      >
        <SelectProduct
          onSelect={onSelectProduct}
        />
      </Form.Item>
      <Form.Item
        name="quantity"
        label="Quantidade"
        initialValue={0}
        rules={[{
          required: true,
          validator: (_, value) => {
            if (value && value > 0) return Promise.resolve();
            if (value && value <= 0) return Promise.reject('O campo deve ser maior que 0');
            return Promise.reject('Campo obrigatório');
          }
        }]}
      >
        <InputNumber
          decimalSeparator=","
          precision={2}
          controls={false}
        />
      </Form.Item>
      <Form.Item
        name="metricUnit"
        label="Unidade de medida"
        wrapperCol={{ sm: 5 }}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        name="observation"
        label="Observação"
        initialValue=""
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item>
        <Button block type="primary" htmlType="submit">Salvar</Button>
      </Form.Item>
    </Form>
  );
}