import { create } from 'zustand';
import { useGlobalMessageStore } from '../../../components/GlobalMessageProvider/store';
import { Pagination } from '../../../types/Pagination';
import { Seller } from '../../Seller/types/Seller';
import { BudgetItemResult } from '../types/BudgetItemResult';
import { getBudgetItemsResults, getBudgetSellers, getBudgetItemsResultsLoosers } from './services';

export interface BudgetResultStore {
  init: () => void;
  isLoading: boolean;
  budgetResults: BudgetItemResult[];
  budgetResultsLoosers: BudgetItemResult[];
  getBudgetResults: ({ sellerId, winnersOnly }: { sellerId?: number; winnersOnly?: boolean }) => void;
  getBudgetResultsLoosers: (budgetItemId: number) => void;
  budgetResultsPagination: Pagination;
  setPagination: (pagination: Partial<Pagination>) => void;
  currentBudgetId: number | null;
  setBudgetId: (budgetId: number) => void;
  getBudgetSellers: () => void;
  budgetSellers: Seller[];
}

const DEFAULT_LIMIT = 50;

export const useBudgetResultStore = create<BudgetResultStore>()(
  (set, get) => {
    return {
      init() {
        set({
          isLoading: false, budgetResults: [], budgetResultsPagination: { limit: DEFAULT_LIMIT, page: 1, pages: 1, total: 1 }
        });
      },
      isLoading: false,
      budgetResults: [],
      budgetResultsLoosers: [],
      async getBudgetResults({ sellerId, winnersOnly } = {}) {
        try {
          const budgetId = get().currentBudgetId;
          const { limit, page } = get().budgetResultsPagination;
          if (budgetId) {
            set({ isLoading: true });
            const response = await getBudgetItemsResults({ budgetId, limit, page, sellerId, winnersOnly })
            const { data, ...pagination } = response;
            set({ isLoading: false, budgetResults: data, budgetResultsPagination: pagination });
          }
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível buscar os itens do orçamento',
            description: 'Tente novamente em instantes',
          });
        }
      },
      async getBudgetResultsLoosers(budgetItemId: number) {
        try {
          const budgetId = get().currentBudgetId;
          if (budgetId) {
            set({ isLoading: true });
            const response = await getBudgetItemsResultsLoosers({ budgetId, budgetItemId, limit: 100, page: 1 });
            const { data } = response;
            set({ isLoading: false, budgetResultsLoosers: data });
          }
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível buscar os itens perdedores do orçamento',
            description: 'Tente novamente em instantes',
          });
        }
      },
      budgetResultsPagination: { limit: DEFAULT_LIMIT, page: 1, pages: 1, total: 1 },
      setPagination({ page, limit }) {
        const budgetResultsPagination = get().budgetResultsPagination;
        if (page) set({ budgetResultsPagination: { ...budgetResultsPagination, page } });
        if (limit) set({ budgetResultsPagination: { ...budgetResultsPagination, limit } });
        get().getBudgetResults({});
      },
      currentBudgetId: null,
      setBudgetId(budgetId) {
        set({ currentBudgetId: budgetId });
      },
      budgetSellers: [],
      async getBudgetSellers() {
        try {
          const budgetId = get().currentBudgetId;
          if (budgetId) {
            set({ isLoading: true });
            const sellers = await getBudgetSellers({ budgetId });
            set({ isLoading: false, budgetSellers: sellers });
          }
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível buscar os vendedores do orçamento',
            description: 'Tente novamente em instantes',
          });
        }
      },
    }
  }
);
