import { httpClient } from '../../../helpers/httpClient';
import { PaginatedData } from '../../../types/PaginatedData';
import { Seller } from '../types/Seller';

const authClient = httpClient();

export const getSellers = async (
  data: { searchTerm?: string, page: number, limit: number }
) => {
  if (!data.searchTerm) delete data.searchTerm;
  const params = new URLSearchParams(data as Record<string, any>);
  const response = await authClient().get<PaginatedData<Seller>>(`/seller?${params.toString()}`);
  return response.data;
};

export const createSeller = async (seller: Seller) => {
  await authClient().post('/seller', seller);
};

export const updateSeller = async (seller: Seller) => {
  await authClient().put('/seller', seller);
};
