import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Steps, Result, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../../../components/PageHeader';
import { useCurrentPage } from '../../../hooks';
import { useSellerStore } from '../../Seller/store';
import { useBudgetItemStore, useBudgetStore } from '../store';
import { BudgetStepsEnum } from '../types/BudgetStepsEnum';
import { BudgetForm } from './BudgetForm';
import { BudgetStatusTag } from '../components/BudgetStatusTag';
import { BudgetItems } from './BudgetItems';
import { BudgetPublish } from './BudgetPublish';
import { BudgetCloseButton } from '../components/BudgetCloseButton';

export const BudgetFormContainer: React.FC = () => {
  const params = useParams<{ budgetId: string }>();
  const { navigate, subdomain } = useCurrentPage();
  const budgetId = params.budgetId ? Number(params.budgetId) : null;
  
  const {
    isLoading,
    getBudget,
    budget,
    init,
    createBudget,
    updateBudget,
    createSuccess,
    updateSuccess,
    currentBudgetId,
    resetSaveParams,
    budgetNotFound,
    publishBudget,
    closeBudget,
  } = useBudgetStore();

  const {
    budgetItems,
    setBudgetId,
    createBudgetItem,
    deleteBudgetItem,
    isLoading: isLoadingBudgetItems,
    budgetItemsPagination,
    setPagination,
    isCreateItemDrawerOpened,
    openCreateItemDrawer,
    closeCreateItemDrawer
  } = useBudgetItemStore();

  const { sellers, getSellers, isLoading: isLoadingSellers } = useSellerStore();
  const [currentStep, setCurrentStep] = useState<BudgetStepsEnum>(BudgetStepsEnum.budgetData);
  
  useEffect(() => {
    init();
    getSellers({ page: 1, limit: 500, searchTerm: '' })
  }, []);

  useEffect(() => {
    if (budgetId) {
      setBudgetId(budgetId);
      getBudget(budgetId);
    }
  }, [budgetId, getBudget, setBudgetId]);

  useEffect(() => {
    if(createSuccess && currentBudgetId) {
      setCurrentStep(BudgetStepsEnum.budgeItems);
      navigate(`/${subdomain}/budget/${currentBudgetId}/edit`);
    }

    if (updateSuccess) {
      resetSaveParams();
      setCurrentStep(BudgetStepsEnum.budgeItems);
    }
  }, [createSuccess, currentBudgetId, navigate, resetSaveParams, subdomain, updateSuccess]);

  const onChangeStep = (current: BudgetStepsEnum) => {
    setCurrentStep(current);
  };

  const onBackButton = () => {
    navigate(`/${subdomain}/budget`)
  };

  const handleOnClickPublish = () => {
    publishBudget();
  };

  return (
    <>
      <PageHeader title={`${budgetId ? 'Edição' : 'Criação'} de orçamento`} level={2} />
      <Card>
        {
          !budgetNotFound ? (
            <>
              <Row>
                <Col>
                  <BudgetStatusTag budgetStatus={budget?.status} />
                </Col>
                <Col>
                  <BudgetCloseButton budget={budget} onBack={onBackButton} onClose={closeBudget}  />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <Steps
                    progressDot
                    onChange={onChangeStep}
                    direction="vertical"
                    current={currentStep}
                    items={[
                      {
                        title: 'Dados do orçamento',
                        description: 'Aqui você vai preencher os dados do seu orçamento e quem irá responder.'
                      },
                      {
                        title: 'Itens do orçamento',
                        disabled: !budget,
                        description: 'Aqui você vai selecionar os itens do seu orçamento.',
                      },
                      {
                        title: 'Publicação',
                        disabled: !budget,
                        description: 'Aqui você vai revisar e publicar seu orçamento e nós vamos enviá-lo para as pessoas que irão responder.',
                      },
                    ]}
                  />
                </Col>
                <Col sm={20} style={{ paddingLeft: '20px' }}>
                  {
                    currentStep === BudgetStepsEnum.budgetData && (
                      <BudgetForm
                        key={currentBudgetId ? `budget_${currentBudgetId}` : 'budget_new'}
                        budget={budget}
                        sellers={sellers}
                        isLoading={isLoading || isLoadingSellers}
                        saveFunction={budget?.id ? updateBudget : createBudget}
                      />
                    )
                  }
                  {
                    currentStep === BudgetStepsEnum.budgeItems && (
                      <BudgetItems
                        budgetItems={budgetItems}
                        budget={budget}
                        setBudgetId={setBudgetId}
                        createBudgetItem={createBudgetItem}
                        deleteBudgetItem={deleteBudgetItem}
                        isLoading={isLoadingBudgetItems}
                        pagination={budgetItemsPagination}
                        setPagination={setPagination}
                        isCreateItemDrawerOpened={isCreateItemDrawerOpened}
                        closeCreateItemDrawer={closeCreateItemDrawer}
                        openCreateItemDrawer={openCreateItemDrawer}
                      />
                    )
                  }
                  {
                    currentStep === BudgetStepsEnum.budgetPublish && (
                      <BudgetPublish
                        budget={budget}
                        budgetItems={budgetItems}
                        isLoading={isLoading || isLoadingSellers}
                        setBudgetId={setBudgetId}
                        onChangeStep={onChangeStep}
                        onClickPublish={handleOnClickPublish}
                        onClickBackButton={onBackButton}
                      />
                    )
                  }
                </Col>
              </Row>
            </>
          ) : (
            <>
                <Result
                  status="404"
                  title="404"
                  subTitle="Orçamento não encontrado!"
                  extra={<Button type="primary" onClick={onBackButton}>Voltar</Button>}
                />
            </>
          ) 
        }
      </Card>
    </>
  );
};
