import React, { useEffect } from 'react';
import { Layout, Col, Row } from 'antd';
import { LoginForm } from '../../containers/Authentication'
import { useAuthStore } from '../../containers/Authentication/store';

export const LoginPage: React.FC = () => {
  const { logout } = useAuthStore();
  useEffect(() => {
    logout(() => {});
  }, [])

  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Layout.Content>
          <Row align="middle">
            <Col sm={6}>
              <LoginForm />
            </Col>
            <Col sm={18}style={{ textAlign: 'center', height: "100vh", backgroundColor: '#0E111E' }}>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </>
  );
};
