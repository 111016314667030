import React, { useEffect, useState } from 'react';
import { InputNumber } from 'antd';

type Props = {
  value: string | null;
  onChange: (value: number) => void;
  disabled: boolean;
}

export const InputBudgetAnswerPrice: React.FC<Props> = ({ value, onChange, disabled }) => {
  const [initialValue, setInitialValue] = useState<number>(Number(value));
  const [currentValue, setCurrentValue] = useState<number>(Number(value));

  useEffect(() => {
    setInitialValue(Number(value));
  }, [value]);

  const handleOnBlur = () => {
    if (initialValue !== currentValue) {
      onChange(currentValue);
    }
  }

  const handleOnChange = (value: number | null) => {
    if(value) {
      setCurrentValue(value);
    }
  }
  return (
    <InputNumber
      disabled={disabled}
      size="small"
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      rootClassName="input-price"
      defaultValue={currentValue}
      decimalSeparator=","
      precision={2}
      controls={false}
      tabIndex={0}
    />
  );
}