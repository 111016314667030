import { create } from 'zustand';
import { Pagination } from '../../../types/Pagination';
import { GetUsersParams } from '../types/GetUsersParams';
import { PaginatedUser } from '../types/PaginatedUser';
import { SaveUserParams } from '../types/SaveUserParams';
import { UpdateUserParams } from '../types/UpdateUserParams';
import { User } from '../types/User';
import { getUsers, saveUser, updateUser } from './services';

export type UserStore = {
  users: PaginatedUser['data'];
  usersPagination: Pagination;
  getUsersLoading: boolean;
  getUsersError: boolean;
  getUsers: ({ searchTerm, page, limit }: GetUsersParams) => Promise<void>,
  saveUserIsLoading: boolean,
  saveUserError: boolean,
  saveUserSuccess: boolean,
  saveUser: (
    user: SaveUserParams,
    onSaveSuccess: () => void,
    onSaveError: () => void,
  ) => Promise<void>,
  updateUserIsLoading: boolean,
  updateUser: ({
    user,
    onSuccess,
    onError,
  }: {
      user: User,
      onSuccess: () => void,
      onError: () => void,
  }) => Promise<void>,
};

export const useUserStore = create<UserStore>()(
  (set) => {
    return {
      getUsersLoading: false,
      getUsersError: false,
      users: [],
      usersPagination: { limit: 10, page: 1, pages: 1, total: 1 },
      getUsers: async ({ searchTerm, limit, page }) => {
        try {
          set({ getUsersLoading: true, getUsersError: false })
          const response = await getUsers({ searchTerm, limit, page });
          set({
            users: response.data,
            usersPagination: { page: response.page, limit: response.limit, total: response.total, pages: response.pages },
          });
          set({ getUsersLoading: false })
        } catch (error) {
          console.error(error);
          set({ getUsersLoading: false, getUsersError: true })
        }
      },
      saveUserIsLoading: false,
      saveUserError: false,
      saveUserSuccess: false,
      saveUser: async (user, onSaveSuccess, onSaveError) => {
        try {
          set({ saveUserIsLoading: true, saveUserError: false, saveUserSuccess: false });
          await saveUser(user);
          set({ saveUserIsLoading: false, saveUserSuccess: true });
          onSaveSuccess();
        } catch (error) {
          set({ saveUserIsLoading: false, saveUserError: true });
          onSaveError();
        }
      },
      updateUserIsLoading: false,
      updateUser: async ({ user, onSuccess, onError }) => {
        try {
          set({ updateUserIsLoading: true });
          await updateUser(user);
          set({ updateUserIsLoading: false });
          onSuccess();
        } catch (error) {
          set({ updateUserIsLoading: false });
          onError();
        }
      }
    }
  }
);