import React from 'react';
import { PageContent } from '../../components/PageContent';
import { PageHeader } from '../../components/PageHeader';
import { ProductTemplateContainer } from '../../containers/Product/ProductTemplates';

export const ProductTemplatesPage: React.FC = () => {
  return (
    <>
      <PageContent>
        <>
          <PageHeader
            title="Templates de importação"
            level={2}
            subtitle="Criação e edição de templates de importação de produtos"
          />
          <ProductTemplateContainer />
        </>
      </PageContent>
    </>
  );
};
