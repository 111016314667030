import { create } from 'zustand';
import { useGlobalMessageStore } from '../../../components/GlobalMessageProvider/store';
import { Company, CompanyBySubdomainResponse } from '../types/Company';
import { Pagination } from '../../../types/Pagination';
import {
  getCompanies,
  createCompany,
  updateCompany,
  getCompanyBySubdomain,
} from './services';

const DEFAULT_LIMIT = 10;

export interface CompanyStore {
  init: () => void;
  isLoading: boolean;
  isLoadingSubdomain: boolean;
  companies: Company[];
  selectedCompany: Company | null;
  currentFilter: { searchTerm: string }
  getCompanies: ({ searchTerm }: { searchTerm: string }) => void;
  pagination: Pagination;
  setPagination: (pagination: Partial<Pagination>) => void;
  isModalOpened: boolean;
  setCurrentCompany: (company: Company | null) => void;
  setIsModalOpened: (isModalOpened: boolean) => void;
  createCompany: (company: Omit<Company, 'id'>) => void;
  updateCompany: (company: Company) => void;
  getCompanyBySubdomain: (subdomain: string) => void;
  subdomainNotFound: boolean;
  subDomainCompany: CompanyBySubdomainResponse | null;
};

export const useCompanyStore = create<CompanyStore>()(
  (set, get) => {
    return {
      init() {
        set({
          isLoading: false,
          isLoadingSubdomain: false,
          companies: [],
          currentFilter: { searchTerm: '' },
          pagination: { limit: DEFAULT_LIMIT, page: 1, pages: 1, total: 1 },
          isModalOpened: false,
          subdomainNotFound: false,
        })
      },
      isLoading: false,
      isLoadingSubdomain: false,
      companies: [],
      selectedCompany: null,
      currentFilter: { searchTerm: '' },
      async getCompanies({ searchTerm }) {
        try {
          set({ isLoading: true, currentFilter: { searchTerm } });
          const { limit, page } = get().pagination;
          const { data, ...pagination } = await getCompanies({ searchTerm, limit, page });
          set({ companies: data, pagination, isLoading: false });
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível buscar as empresas',
            description: 'Tente novamente em instantes',
          });
        }
      },
      pagination: { limit: DEFAULT_LIMIT, page: 1, pages: 1, total: 1 },
      setPagination({ page, limit }) {
        const pagination = get().pagination;
        if (page) set({ pagination: { ...pagination, page } });
        if (limit) set({ pagination: { ...pagination, limit } });
        get().getCompanies(get().currentFilter);
      },
      isModalOpened: false,
      setCurrentCompany(company) {
        set({ selectedCompany: company });
        get().setIsModalOpened(true);
      },
      setIsModalOpened(isModalOpened) {
        set({ isModalOpened });
      },
      async createCompany(company) {
        try {
          set({ isLoading: true });
          await createCompany({ company });
          set({ isLoading: false });
          get().setIsModalOpened(false);
          get().getCompanies(get().currentFilter);
          useGlobalMessageStore.getState().successNotification({
            message: 'Empresa criada com sucesso',
          });
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível criar a empresa',
            description: 'Tente novamente em instantes',
          });
        }
      },
      async updateCompany(company) {
        try {
          set({ isLoading: true });
          await updateCompany({ company });
          set({ isLoading: false });
          get().setIsModalOpened(false);
          get().getCompanies(get().currentFilter);
          useGlobalMessageStore.getState().successNotification({
            message: 'Empresa atualizada com sucesso',
          });
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível atualizar a empresa',
            description: 'Tente novamente em instantes',
          });
        }
      },
      subDomainCompany: null,
      subdomainNotFound: false,
      async getCompanyBySubdomain(subdomain) {
        try {
          set({ isLoadingSubdomain: true, subdomainNotFound: false });
          const response = await getCompanyBySubdomain(subdomain);
          if (!response) {
            set({ isLoadingSubdomain: false, subdomainNotFound: true, subDomainCompany: null });
            return;
          }
          set({ isLoadingSubdomain: false, subdomainNotFound: false, subDomainCompany: response });
        } catch (error) {
          console.error(error);
          set({ isLoadingSubdomain: false, subdomainNotFound: true, subDomainCompany: null });
        }
      },
    }
  }
);