import React from 'react';
import { Menu } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';

type NavMenuProps = {
  items: ItemType[],
  currentPage: string,
  setCurrentPage: (value: string) => void
};

export const SideMenu: React.FC<NavMenuProps> = ({ items, currentPage, setCurrentPage }) => {
  const onClick = ({ key }: { key: string }) => {
    setCurrentPage(key);
  }

  return (
    <>
      <Menu
        style={{ float: 'right', width: '100%' }}
        theme="light"
        items={items}
        mode="vertical"
        onClick={onClick}
        selectedKeys={[currentPage]}
      />
    </>
  );
};
