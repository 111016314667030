import React, { useEffect } from 'react';
import { useBudgetResultStore } from '../store/budgetResultStore';
import { BudgetResultsList } from './BudgetResultsList';
import { Alert, Card, Tabs } from 'antd';

type Props = {
  budgetId?: string;
}

export const BudgeResultsItemsBySellers: React.FC<Props> = ({ budgetId }) => {
  const {
    isLoading,
    getBudgetResults,
    budgetResults,
    budgetResultsPagination,
    setPagination,
    setBudgetId,
    getBudgetSellers,
    budgetSellers,
    getBudgetResultsLoosers,
    budgetResultsLoosers,
    currentBudgetId,
  } = useBudgetResultStore();

  useEffect(() => {
    setBudgetId(Number(budgetId));
    getBudgetSellers();
  }, []);

  return (
    <Card>
      <Tabs
        tabPosition="left"
        defaultActiveKey="0"
        destroyInactiveTabPane
        items={[
          ...budgetSellers.map(seller => {
            return {
              label: seller.name,
              key: seller.email,
              children: (
                <>
                  <Alert
                    style={{ marginBottom: '8px' }}
                    type="info"
                    message="O vendedor pode escolher não responder alguns itens do orçamento. Isso pode fazer o valor total do orçamento ficar bem abaixo dos outros. Fique atento!"
                    showIcon
                  />
                  <BudgetResultsList
                    isLoading={isLoading}
                    budgetResults={budgetResults}
                    getBudgetResults={getBudgetResults}
                    pagination={budgetResultsPagination}
                    setPagination={setPagination}
                    getBudgetResultsLoosers={getBudgetResultsLoosers}
                    budgetResultsLoosers={budgetResultsLoosers}
                    expandable={false}
                    sellerId={seller.id}
                    winnersOnly={false}
                    key={seller.id}
                    budgetId={currentBudgetId}
                  />
                </>
              )
            }
          }),
        ]}
      />
    </Card>
  );
};