import queryString from 'query-string';
import { httpClient } from '../../../../helpers/httpClient';
import { PaginatedData } from '../../../../types/PaginatedData';
import { BudgetAnswer } from '../../types/BudgetAnswer';
import { BudgetAnswerItem } from '../../types/BudgetAnswerItem';

const authClient = httpClient();

export const getBudgetAnswer = async ({ budgetAnswerId }: { budgetAnswerId: string }) => {
  const response = await authClient().get<BudgetAnswer>(`/budgetAnswer/${budgetAnswerId}`)
  return response.data;
};

export const getBudgetAnswerItems = async (
  { budgetAnswerId, page, limit }: { budgetAnswerId: string; page: number; limit: number }
) => {
  const params = queryString.stringify({ page, limit }, { arrayFormat: 'bracket' });
  const response = await authClient().get<PaginatedData<BudgetAnswerItem>>(`/budgetAnswer/${budgetAnswerId}/item?${params}`)
  return response.data;
};

export const updateBudgetAnswerItem = async (
  { budgetAnswerItem, budgetAnswerId }: { budgetAnswerItem: BudgetAnswerItem; budgetAnswerId: string }
) => {
  const { id, price, observations = '', smallerQuantity, quantity } = budgetAnswerItem;
  
  await authClient().post(`/budgetAnswer/${budgetAnswerId}/item/${id}`, {
    price,
    observations: observations || '',
    smallerQuantity: smallerQuantity || quantity,
  });
};

export const closeBudgetAnswer = async (
  { budgetAnswerId }: { budgetAnswerId: string }
) => {
  await authClient().post(`/budgetAnswer/${budgetAnswerId}/close`);
};