import { create } from 'zustand';
import { BudgetAnswer } from '../types/BudgetAnswer';
import { useGlobalMessageStore } from '../../../components/GlobalMessageProvider/store';
import {
  getBudgetAnswer,
  getBudgetAnswerItems,
  updateBudgetAnswerItem,
  closeBudgetAnswer,
} from './services/budgetAnswerService';
import { BudgetAnswerItem } from '../types/BudgetAnswerItem';
import { Pagination } from '../../../types/Pagination';

export interface BudgetAnswerStore {
  init: () => void;
  isLoading: boolean;
  currentBudgetAnswer: BudgetAnswer | null;
  budgetAnswerItems: BudgetAnswerItem[];
  setCurrentBudgetAnswerId: (budgetAnswerId: string) => void;
  budgetAnswerItemsPagination: Pagination;
  setPagination: (pagination: Partial<Pagination>) => void;
  getBudgetAnswerItems: () => void;
  updateBudgetAnswerItem: (item: BudgetAnswerItem) => void;
  closeBudgetAnswer: () => void;
};

export const useBudgetAnswerStore = create<BudgetAnswerStore>()(
  (set, get) => {
    return {
      init() {
        set({ isLoading: false, currentBudgetAnswer: null });
      },
      isLoading: false,
      currentBudgetAnswer: null,
      budgetAnswerItems: [],
      async setCurrentBudgetAnswerId(budgetAnswerId) {
        try {
          set({ isLoading: true });
          const response = await getBudgetAnswer({ budgetAnswerId });
          set({ currentBudgetAnswer: response, isLoading: false });
          get().getBudgetAnswerItems();
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível buscar o orçamento',
            description: 'Tente novamente em instantes',
          });
        }
      },
      budgetAnswerItemsPagination: { page: 1, pages: 1, limit: 20, total: 1 },
      setPagination({ page, limit }) {
        const currentBudgetAnswer = get().currentBudgetAnswer;
        const budgetAnswerItemsPagination = get().budgetAnswerItemsPagination;
        if (page) set({ budgetAnswerItemsPagination: { ...budgetAnswerItemsPagination, page } });
        if (limit) set({ budgetAnswerItemsPagination: { ...budgetAnswerItemsPagination, limit } });

        if (currentBudgetAnswer) {
          get().getBudgetAnswerItems();
        }
      },
      async getBudgetAnswerItems() {
        try {
          const currentBudgetAnswer = get().currentBudgetAnswer;
          const { page, limit } = get().budgetAnswerItemsPagination;
          if (currentBudgetAnswer && currentBudgetAnswer.id) {
            set({ isLoading: true })
            const response = await getBudgetAnswerItems(
              { budgetAnswerId: currentBudgetAnswer.id, page, limit }
            );
            const { data, ...pagination } = response;
            set({ budgetAnswerItems: data, budgetAnswerItemsPagination: pagination, isLoading: false });
          }
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível buscar os itens do orçamento',
            description: 'Tente novamente em instantes',
          });
        }
      },
      async updateBudgetAnswerItem(item) {
        try {
          const currentBudgetAnswer = get().currentBudgetAnswer;
          if (currentBudgetAnswer && currentBudgetAnswer.id) {
            set({ isLoading: true });
            await updateBudgetAnswerItem({ budgetAnswerItem: item, budgetAnswerId: currentBudgetAnswer.id });
            set({ isLoading: false });
            get().getBudgetAnswerItems();
          }
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível salvar o item do orçamento',
            description: 'Tente novamente em instantes',
          });
        }
      },
      async closeBudgetAnswer() {
        try {
          const currentBudgetAnswer = get().currentBudgetAnswer;
          if (currentBudgetAnswer && currentBudgetAnswer.id) {
            set({ isLoading: true });
            await closeBudgetAnswer({ budgetAnswerId: currentBudgetAnswer.id });
            set({ isLoading: false });
            useGlobalMessageStore.getState().successNotification({
              message: 'Orçamento respondido com sucesso!',
              description: 'A empresa já recebeu suas respostas.',
            });
            get().setCurrentBudgetAnswerId(currentBudgetAnswer.id);
          }    
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível finalizar orçamento',
            description: 'Tente novamente em instantes',
          });
        }
      },
    }
  }
);
