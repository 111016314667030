import React, { useEffect } from 'react';
import { Modal, notification } from 'antd';
import { useGlobalMessageStore } from './store';

type Props = {
  children: React.ReactElement;
};

export const GlobalMessageProvider: React.FC<Props> = ({ children }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const [modalApi, modalContextHolder] = Modal.useModal();
  const { setNotificationApi, setModalApi } = useGlobalMessageStore();

  useEffect(() => {
    setNotificationApi(notificationApi);
    setModalApi(modalApi);
  }, [])
  
  return (
    <>
      {modalContextHolder}
      {contextHolder}
      {children}
    </>
  );
};
