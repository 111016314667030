import React, { useEffect, useRef, useState } from 'react';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Row, Space } from 'antd';
import { BudgetList } from './BudgetList';
import { useBudgetStore } from './store';
import { BudgetFilter } from './components/BudgetFilter';
import { useFilterParams } from './hooks/useFilterParams';
import { isEqual } from 'lodash';
import { useCurrentPage } from '../../hooks';

export const BudgetContainer: React.FC = () => {
  const { navigate } = useCurrentPage();
  const { init, getBudgets, budgets, budgetsPagination, isLoading } = useBudgetStore();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { filters } = useFilterParams();
  const filtersRef = useRef(filters);

  useEffect(() => {
    if (!isEqual(filtersRef.current, filters)) {
      getBudgets({ searchTerm, page: 1, limit: 12, status: filters.status })
      filtersRef.current = filters;
    }
  }, [filters, getBudgets]);
  
  useEffect(() => {
    getBudgets({ searchTerm, page: 1, limit: 12, status: filters.status })
    return () => {
      init();
    }
  }, []);

  const onNew = () => {
    navigate('new');
  };

  const onPageChange = (page: number) => {
    getBudgets({ searchTerm: '', page, limit: 12, status: filters.status });
  };

  const onSearch = (value: string) => {
    setSearchTerm(value);
    getBudgets({ searchTerm: value, page: 1, limit: 12, status: filters.status });
  };

  return (
    <>
      <BudgetFilter
        isOpen={isFilterDrawerOpen}
        onClose={() => setIsFilterDrawerOpen(false)}
      />
      <Row style={{ paddingBottom: '5px', display: 'flex', justifyContent: 'flex-end' }}>
        <Space.Compact direction="horizontal" style={{ justifyContent: 'left' }}>
          <Button icon={<PlusOutlined />} onClick={onNew}>Criar orçamento</Button>
          <Button type="primary"  icon={<FilterOutlined />} onClick={() => setIsFilterDrawerOpen(true)}>Filtros</Button>
        </Space.Compact>
      </Row>
      <Card>
        <BudgetList
          onSearch={onSearch}
          isLoading={isLoading}
          onPageChange={onPageChange}
          pagination={budgetsPagination}
          budgets={budgets}
        />
      </Card>
    </>
  );
};
