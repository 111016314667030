import React from 'react';
import { Col, Menu, Row } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';

type NavMenuProps = {
  items: ItemType[],
  currentPage: string,
  setCurrentPage: (value: string) => void
  onMenuLogoClick: () => void
  onLogout: () => void;
};

const logoStyle: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: '8px 0 0 0',
};

export const NavMenu: React.FC<NavMenuProps> = ({ items, currentPage, setCurrentPage, onMenuLogoClick }) => {
  const onClick = ({ key }: { key: string }) => {
    setCurrentPage(key);
  }

  const onLogoClick = (e: any) => {
    e.preventDefault();
    onMenuLogoClick();
  }

  return (
    <>
      <Row style={{ marginLeft: 0 }}>
        <Col sm={2} xs={8}>
          <div className="logo" style={logoStyle}>
            <a href="/" onClick={onLogoClick}>
              <img
                width="90%"
                src={`${process.env.PUBLIC_URL}/assets/logo-new.png`}
                alt="logo"
              />
            </a>
          </div>  
        </Col>
        <Col sm={22} xs={16}>
          <Row>
            <Menu
              style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', backgroundColor: '#0E111E' }}
              theme="dark"
              items={items}
              mode="horizontal"
              onClick={onClick}
              selectedKeys={[currentPage]}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};
