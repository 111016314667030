import axios, { AxiosError } from 'axios';
import { configs } from '../configs';

export const httpClient = ({ ignoreRedirect = false } = {}) => ({ statusCodesToRedirect = [401] } = {}) => {
  const authToken = JSON.parse(window.localStorage.getItem('auth_token') || 'null');
  
  const subdomain = JSON.parse(window.localStorage.getItem('subdomain') || 'null');
  const { origin } = window.location;

  const client = axios.create({
    baseURL: configs.baseURL,
    headers: {
      Authorization: authToken,
    }
  });

  client.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    const axiosError = error as unknown as AxiosError;
    const loginPageUrl = `${origin}/${subdomain}/login`
    
    if (statusCodesToRedirect.includes(axiosError.response?.status || 0) && !ignoreRedirect) {
      window.location.href = loginPageUrl;
    }
    return Promise.reject(error);
  })
  return client;
}