import React from 'react';
import { PageContent } from '../../components/PageContent';
import { PageHeader } from '../../components/PageHeader';
import { BudgetResultsContainer } from '../../containers/BudgetResults';

export const BudgetResultsPage: React.FC = () => {
  return (
    <>
      <PageContent>
        <>
          <PageHeader
            title="Resultados do orçamento"
            level={2}
          />
          <BudgetResultsContainer />
        </>
      </PageContent>
    </>
  );
};