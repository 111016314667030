import React from 'react';
import { PageContent } from '../../components/PageContent';
import { BudgetFormContainer } from '../../containers/Budget/BudgetForm';

export const BudgetFormEditPage: React.FC = () => {
  return (
    <>
      <PageContent>
        <BudgetFormContainer key="newPage" />
      </PageContent>
    </>
  );
};