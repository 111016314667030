import React, { useState, useEffect } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import { ProductTemplateForm } from './ProductTemplateForm';
import { ProductTemplateList } from './ProductTemplateList';
import { useProductTemplateStore } from './store';
import { ProductTemplate } from './types/ProductTemplate';
import { PlusOutlined } from '@ant-design/icons';

export const ProductTemplateContainer: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<ProductTemplate | null>(null);
  const {
    templates,
    getTemplates,
    getTemplatesIsLoading,
    templatesPagination,
    saveTemplate,
    isSaving,
    saveComplete,
    saveError,
    resetSaveProps,
  } = useProductTemplateStore();

  const onSelectProductTemplate = (template: ProductTemplate) => {
    resetSaveProps();
    setSelectedTemplate(template);
    setIsModalOpen(true);
  };

  const onNew = () => {
    resetSaveProps();
    setSelectedTemplate(null);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (saveComplete) {
      getTemplates({
        page: templatesPagination.page, limit: templatesPagination.limit, searchTerm: ''
      });
    }
  }, [getTemplates, saveComplete, templatesPagination.limit, templatesPagination.page]);

  return (
    <>
      <Row style={{ paddingBottom: '5px' }}>
        <Col span={2} sm={2} offset={22}>
          <Button block icon={<PlusOutlined />} onClick={onNew}>Novo</Button>
        </Col>
      </Row>
      <ProductTemplateList
        templates={templates}
        getProductTemplates={getTemplates}
        isLoading={getTemplatesIsLoading}
        pagination={templatesPagination}
        onSelect={onSelectProductTemplate}
      />
      <Modal
        open={isModalOpen}
        title={`${selectedTemplate ? 'Editar' : 'Criar'} template de importação`}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        destroyOnClose
      >
        <ProductTemplateForm
          selectedTemplate={selectedTemplate}
          saveComplete={saveComplete}
          saveError={saveError}
          saveTemplate={saveTemplate}
          isSaving={isSaving}
        />
      </Modal>
    </>
  );
}