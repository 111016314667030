import React, { useEffect, useState } from 'react';
import { useSellerStore } from './store';
import { SellerList } from './SellerList';
import { Button, Col, Modal, notification, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Seller } from './types/Seller';
import { SellerForm } from './SellerForm';

export const SellerContainer: React.FC = () => {
  const {
    init,
    getSellers,
    sellersPagination,
    sellers,
    isLoading,
    saveSeller,
    saveError,
    saveSuccess
  } = useSellerStore();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedSeller, setSelectedSeller] = useState<Seller | null>(null);
  const { page, limit } = sellersPagination;

  useEffect(() => {
    return () => {
      init();
    }
  }, []);

  const onNew = () => {
    init();
    setSelectedSeller(null);
    setIsModalOpen(true);
  };

  const onSelect = (seller: Seller) => {
    init();
    setSelectedSeller(seller);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (saveSuccess) {
      getSellers({ searchTerm: '', limit, page });
      setIsModalOpen(false);
    }
  }, [getSellers, init, limit, page, saveSuccess]);

  return (
    <>
      {contextHolder}
      <Row style={{ paddingBottom: '5px' }}>
        <Col span={2} sm={2} offset={22}>
          <Button block icon={<PlusOutlined />} onClick={onNew}>Novo</Button>
        </Col>
      </Row>
      <SellerList
        getSellers={getSellers}
        pagination={sellersPagination}
        sellers={sellers}
        onSelect={onSelect}
        isLoading={isLoading}
      />
      <Modal
        open={isModalOpen}
        title={`${selectedSeller ? 'Editar' : 'Criar'} vendedor`}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        destroyOnClose
      >
        <SellerForm
          notificationApi={notificationApi}
          isLoading={isLoading}
          seller={selectedSeller}
          saveError={saveError}
          saveSuccess={saveSuccess}
          saveSeller={saveSeller}
        />
      </Modal>
    </>
  )
}