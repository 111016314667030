import queryString from 'query-string';
import { httpClient } from '../../../helpers/httpClient';
import { PaginatedData } from '../../../types/PaginatedData';
import { Company, CompanyBySubdomainResponse } from '../types/Company';
import axios from 'axios';

const authClient = httpClient();
const unauthClient = httpClient({ ignoreRedirect: true });

type GetCompaniesParams = {
  searchTerm: string;
  page: number;
  limit: number;
};

export const getCompanies = async ({ searchTerm, page, limit }: GetCompaniesParams) => {
  const params = queryString.stringify({ page, limit, searchTerm }, { arrayFormat: 'bracket' });
  const response = await authClient().get<PaginatedData<Company>>(`/company?${params}`);
  return response.data;
}

export const createCompany = async ({ company }: { company: Omit<Company, 'id'> }) => {
  await authClient().post('/company', company);
};

export const updateCompany = async ({ company }: { company: Company }) => {
  await authClient().put('/company', company);
};

export const getCompanyBySubdomain = async (subdomain: string) => {
  try {
    const response = await unauthClient().get<CompanyBySubdomainResponse>(`/company/subdomain/${subdomain}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        return null;
      }
    }
    throw error;
  }
};
