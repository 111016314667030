import React, { useEffect, useState } from 'react';
import { Button, Card, Input, Space, Switch } from 'antd';
import { ProductTemplateStore } from './store';
import { ProductTemplate } from './types/ProductTemplate';
import Table, { ColumnsType } from 'antd/es/table';

type Props = {
  templates: ProductTemplate[];
  getProductTemplates: ProductTemplateStore['getTemplates'];
  isLoading: boolean;
  pagination: ProductTemplateStore['templatesPagination'];
  onSelect: (template: ProductTemplate) => void;
}

export const ProductTemplateList: React.FC<Props> = ({ templates, getProductTemplates, isLoading, pagination, onSelect }) => {
  const { page, limit } = pagination;
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    getProductTemplates({ page, limit, searchTerm })
  }, []);

  const onSearch = (value: string) => {
    setSearchTerm(value);
    getProductTemplates({ searchTerm: value, page, limit })
  };

  const onPageChange = (page: number) => {
    getProductTemplates({ searchTerm, page, limit })
  };

  const columns: ColumnsType<ProductTemplate> = [
    { title: '#', dataIndex: 'id', key: 'id' },
    { title: 'Nome', dataIndex: 'name', key: 'name' },
    { title: 'Separador de coluna', dataIndex: 'separator', key: 'separator' },
    { title: 'Separador de string', dataIndex: 'quote', key: 'quote' },
    {
      title: 'Ignorar cabeçalho',
      dataIndex: 'ignoreHeader',
      key: 'ignoreHeader',
      render: (ignoreHeader) => {
        return (
          <Switch checked={ignoreHeader} disabled />
        );
      },
    },
    { title: 'Coluna Código', dataIndex: 'codePosition', key: 'codePosition' },
    { title: 'Coluna Cód. Barras', dataIndex: 'barcodePosition', key: 'barcodePosition' },
    { title: 'Coluna Descr.', dataIndex: 'descriptionPosition', key: 'descriptionPosition' },
    { title: 'Coluna Unid. Medida', dataIndex: 'metricUnitPosition', key: 'metricUnitPosition' },
    {
      title: 'Ações',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space size="middle" wrap>
            <Button type="primary" size="small" onClick={() => onSelect(record)}>
              Editar
            </Button>
          </Space>
        )
      },
    }
  ];
  return (
    <>
      <Card>
        <Input.Search placeholder="Filtrar por nome" onSearch={onSearch} allowClear loading={isLoading} />
        <Table
          columns={columns}
          dataSource={templates}
          rowKey="id"
          loading={isLoading}
          pagination={{ total: pagination.total, pageSize: pagination.limit, showSizeChanger: false, onChange: onPageChange }}
          locale={{ emptyText: 'Nenhum resultado encontrado' }}
        />
      </Card>
    </>
  );;
};
