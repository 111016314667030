import React from 'react'
import { Budget } from '../types/Budget';
import { Button, Dropdown, Space } from 'antd';
import { BudgetStatus } from '../types/BudgetStatus';
import { useGlobalMessageStore } from '../../../components/GlobalMessageProvider/store';

type Props = {
  onClose: () => void;
  onBack: () => void;
  budget: Budget | null
}

export const BudgetCloseButton: React.FC<Props> = ({ budget, onBack, onClose }) => {
  const { modalApi } = useGlobalMessageStore();
  
  const handleCloseBudget = () => {
    if (modalApi) {
      modalApi.confirm({
        title: 'Tem certeza que deseja encerrar o orçamento?',
        content: 'Após encerrar não será mais possível receber respostas de vendedores.',
        onOk: () => onClose(),
      })
    }
  };
  return (
    <Space direction="vertical">
      {
        budget ? (
          <Dropdown.Button
            type="default"
            size="small"
            menu={{
              items: [
                {
                  key: 'close',
                  label: 'Encerrar orçamento',
                  disabled: budget?.status === BudgetStatus.closed,
                  onClick: () => handleCloseBudget()
                }
              ],
            }}
            onClick={onBack}
          >
            Sair
          </Dropdown.Button>
        ) : (
          <Button
            type="default"
            size="small"
            onClick={onBack}
          >
            Sair
          </Button>
        )
      }
    </Space>
  );
}