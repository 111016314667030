import { Alert, Card, Descriptions, List, Table, Modal, Space } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useRef } from 'react';
import { Pagination } from '../../types/Pagination';
import { InputBudgetAnswerObservations } from './components/InputBudgetAnswerObservations';
import { InputBudgetAnswerPrice } from './components/InputBudgetAnswerPrice';
import { BudgetAnswer, BudgetAnswerStatus } from './types/BudgetAnswer';
import { BudgetAnswerItem } from './types/BudgetAnswerItem';
import { BudgetStatus } from '../Budget/types/BudgetStatus';
import { InputBudgetAnswerSmallerQuantity } from './components/InputBudgetAnswerSmallerQuantity';

type Props = {
  budgetAnswerItems: BudgetAnswerItem[];
  onUpdateItem: (item: BudgetAnswerItem) => void;
  isLoading: boolean;
  pagination: Pagination;
  setPagination: (page: number) => void;
  budgetAnswerStatus: BudgetAnswerStatus | undefined;
  budgetAnswer: BudgetAnswer | null
}

export const BudgetAnswerList: React.FC<Props> = ({
  budgetAnswerItems,
  onUpdateItem,
  isLoading,
  pagination,
  setPagination,
  budgetAnswerStatus,
  budgetAnswer,
}) => {
  const isFirstFocus = useRef(true);
  

  const disabledAnswer =
    budgetAnswerStatus !== BudgetAnswerStatus.draft || (
      budgetAnswerStatus === BudgetAnswerStatus.draft && budgetAnswer?.budgetStatus === BudgetStatus.closed
    )

  useEffect(() => {
    if (budgetAnswerItems.length && !isLoading && isFirstFocus.current) {
      const inputsPrice = document.querySelector('.input-price input') as HTMLInputElement;
      if (inputsPrice) {
        inputsPrice.focus()
        inputsPrice.select()
        isFirstFocus.current = false;
      }
    }
  }, [budgetAnswerItems.length, isLoading]);

  

  const handleOnChangePrice = (value: number, record: BudgetAnswerItem) => {
    const item: BudgetAnswerItem = { ...record, price: String(value) };
    onUpdateItem(item);
  };

  const handleOnChangeSmallerQuantity = (value: number, record: BudgetAnswerItem) => {
    const item: BudgetAnswerItem = { ...record, smallerQuantity: String(value) };
    onUpdateItem(item);
  };

  const handleOnChangeObservations = (value: string, record: BudgetAnswerItem) => {
    const item: BudgetAnswerItem = { ...record, observations: value };
    onUpdateItem(item);
  };

  const handleOnPagination = (page: number) => {
    setPagination(page);
    isFirstFocus.current = true;
  }

  const columns: ColumnsType<BudgetAnswerItem> = [
    {
      title: 'Itens',
      responsive: ['xs'],
      render(value, record, index) {
        return (
          <List.Item>
            <Descriptions
              title={
                <Space direction="horizontal" wrap style={{ width: '100%', lineBreak: 'auto' }}>{record.code} - {record.description}</Space>
              }
            >
              <Descriptions.Item label="Obs. Cliente">{record.observation}</Descriptions.Item>
              <Descriptions.Item label="Quantidade">{record.quantity}</Descriptions.Item>
            </Descriptions>
            <FormItem label="Valor unitário:" wrapperCol={{ span: 24 }} style={{ marginBottom: 0 }}>
              <InputBudgetAnswerPrice
                value={record.price}
                onChange={(value) => handleOnChangePrice(value, record)}
                disabled={disabledAnswer}
              />
            </FormItem>
            <FormItem label="Qtd. disponpivel:" wrapperCol={{ span: 24 }} style={{ marginBottom: 0 }}>
              <InputBudgetAnswerSmallerQuantity
                value={record.smallerQuantity}
                defaultValue={record.quantity}
                disabled={disabledAnswer}
                onChange={(value) => handleOnChangeSmallerQuantity(value, record)}
              />
            </FormItem>
            <FormItem label="Observações:" wrapperCol={{ span: 24 }} style={{ marginBottom: 0 }}>
              <InputBudgetAnswerObservations
                value={record.observations}
                onChange={(value) => handleOnChangeObservations(value, record)}
                disabled={disabledAnswer}
              />
            </FormItem>
          </List.Item>
        )
      },
    },
    { title: 'Código', dataIndex: 'code', key: 'code', responsive: ['md'] },
    { title: 'Cód. Barras', dataIndex: 'barcode', key: 'barcode', responsive: ['md'] },
    { title: 'Descrição', dataIndex: 'description', key: 'description', responsive: ['md'] },
    { title: 'Unid. Medida', dataIndex: 'metricUnit', key: 'metricUnit', responsive: ['md'] },
    { title: 'Qtd.', dataIndex: 'quantity', key: 'quantity', responsive: ['md'] },
    {
      title: 'Valor unitário',
      dataIndex: 'price',
      key: 'price',
      responsive: ['md'],
      render: (_, record) => {
        return (
          <InputBudgetAnswerPrice
            value={record.price}
            onChange={(value) => handleOnChangePrice(value, record)}
            disabled={disabledAnswer}
          />
        );
      }
    },
    {
      title: 'Qtd. Disponível',
      dataIndex: 'smallerQuantity',
      key: 'smallerQuantity',
      responsive: ['md'],
      render(_, record) {
        return (
          <InputBudgetAnswerSmallerQuantity
            value={record.smallerQuantity}
            defaultValue={record.quantity}
            disabled={disabledAnswer}
            onChange={(value) => handleOnChangeSmallerQuantity(value, record)}
          />
        )
      },
    },
    { title: 'Obs. Cliente', dataIndex: 'observation', key: 'observation', responsive: ['md'] },
    {
      title: 'Obs.',
      dataIndex: 'observations',
      key: 'observations',
      responsive: ['md'],
      render: (_, record) => {
        return (
          <InputBudgetAnswerObservations
            value={record.observations}
            onChange={(value) => handleOnChangeObservations(value, record)}
            disabled={disabledAnswer}
          />
        );
      }
    },
  ]
  
  return (
    <Card>
      {
        !disabledAnswer && (
          <>
            <Alert
              style={{ marginBottom: '10px' }}
              message='Você não precisa finalizar a resposta do orçamento agora. Caso queira, você pode abrir o link e continuar respondendo depois.'
              type="info"
              showIcon
              closable
            />
            <Alert
              style={{ marginBottom: '10px' }}
              message='Caso você não tenha algum produto da lista, basta deixar o campo "Valor" vazio.'
              type="info"
              showIcon
              closable
            />
          </>
        )
      }
      <Table
        loading={isLoading}
        size="small"
        dataSource={budgetAnswerItems}
        columns={columns}
        rowKey='id'
        pagination={{
          total: pagination.total,
          pageSize: pagination.limit,
          position: ['bottomRight'],
          onChange: (page) => handleOnPagination(page),
        }}
      />
    </Card>
  );
}