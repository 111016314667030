import React from 'react';
import { PageContent } from '../../components/PageContent';
import { PageHeader } from '../../components/PageHeader';
import { ProductImportContainer } from '../../containers/Product/ProductImport';

export const ProductImportPage: React.FC = () => {
  return (
    <>
      <PageContent>
        <>
          <PageHeader
            title="Importação em massa"
            level={2}
            subtitle="Importação em massa de produtos"
          />
          <ProductImportContainer />
        </>
      </PageContent>
    </>
  );
};
