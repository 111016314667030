import { FileOutlined, IdcardOutlined, ImportOutlined, UserOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';

export const createSetupMenuItems = ({ subdomain }: { subdomain: string }): ItemType[] => {
  return [
    { type: 'divider' },
    {
      label: 'Acesso à plataforma',
      type: 'group',
      children: [
        {
          label: 'Usuários',
          key: `/${subdomain}/setup/users`,
          icon: <UserOutlined />,
        },
        {
          label: 'Vendedores',
          key: `/${subdomain}/setup/sellers`,
          icon: <IdcardOutlined />,
        }
      ],
    },
    { type: 'divider' },
    {
      label: 'Produtos',
      type: 'group',
      children: [
        {
          label: 'Templates de importação',
          key: `/${subdomain}/setup/product/template`,
          icon: <FileOutlined />,
        },
        {
          label: 'Importação em massa',
          key: `/${subdomain}/setup/product/import`,
          icon: <ImportOutlined />,
        }
      ],
    },
  ];
};

