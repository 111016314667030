import React, { useEffect, useMemo, useState } from 'react';
import { RcFile } from 'antd/es/upload';
import { ProductTemplate } from '../ProductTemplates/types/ProductTemplate';
import { parse } from 'papaparse';
import { mapRow, PreviewProduct } from './helpers';
import { Alert, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';

type Props = {
  file: RcFile | null;
  template: ProductTemplate | null;
};

export const FilePreview: React.FC<Props> = ({ file, template }) => {
  const [previewData, setPreviewData] = useState<PreviewProduct[]>([]);

  useEffect(() => {
    if (file && template) {
      parse(file, {
        header: true,
        preview: 5,
        worker: true,
        quoteChar: template.quote,
        delimiter: template.separator,
        complete: (result) => {
          const parsed = result
            .data
            .map((row) => mapRow(row as Record<string, any>, template))
          setPreviewData(parsed)
        },
      })
    }
  }, [file, template]);

  const columns: ColumnsType<PreviewProduct> = [
    { title: 'Código', key: 'code', dataIndex: 'code' },
    { title: 'Código de barras', key: 'barcode', dataIndex: 'barcode' },
    { title: 'Descrição', key: 'description', dataIndex: 'description' },
    { title: 'Unidade de medida', key: 'metricUnit', dataIndex: 'metricUnit' },
  ]

  return (
    <>
      {
        (file && template) && (
          <>
            <Typography.Title level={5} style={{ marginTop: 0 }}>
              Preview da importação
            </Typography.Title>
            <Alert message="Esse é um preview com os primeiros 5 itens do seu arquivo. Fique atento se está usando o template correto!" type="warning" showIcon />
            <Table
              style={{ marginTop: '10px' }}
              size="small"
              columns={columns}
              dataSource={previewData}
              pagination={false}
              rowKey={(row) => {
                const randomId = (Math.random() * new Date().getTime()).toFixed();
                return `${randomId}_${row.code}_${row.barcode}_${row.metricUnit}_${row.description}`
              }}
            />
          </>
        )
      }
    </>
  );
}