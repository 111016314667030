import { Col, Row, Space, Typography } from 'antd';
import { TitleProps } from 'antd/es/typography/Title';
import React from 'react';

type Props = {
  title?: string;
  subtitle?: string;
  level?: TitleProps['level']
};

export const PageHeader: React.FC<Props> = ({ title, subtitle, level = 1 }) => {
  return (
    <Row>
      <Col span={24}>
        <Space.Compact direction="vertical">
          {
            title && (
              <Typography.Title
                level={level}
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                {title}
              </Typography.Title>
            )
          }
          {
            subtitle && (
              <Typography.Text
                type="secondary"
              >
                {subtitle}
              </Typography.Text>
            )
          }
        </Space.Compact>
      </Col>
    </Row>
  );
};
