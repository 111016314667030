import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';

type Props = {
  onSelectFile: (file: RcFile | null) => void;
  disabled?: boolean;
}

export const FileUpload: React.FC<Props> = ({ onSelectFile, disabled = false }) => {
  const [fileList, setFileList] = useState<RcFile[]>([])

  const beforeUpload = (file: RcFile) => {
    setFileList([file]);
    onSelectFile(file);
    return false;
  }

  const onRemove = () => {
    setFileList([]);
    onSelectFile(null);
  };

  return (
    <>
      <Dragger
        accept="text/csv"
        maxCount={1}
        beforeUpload={beforeUpload}
        disabled={disabled}
        fileList={fileList}
        onRemove={onRemove}
        showUploadList={false}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Clique ou arraste um arquivo para fazer upload</p>
      </Dragger>
    </>
  );
}