import React, { useEffect } from 'react';
import { Company } from './types/Company';
import { Button, Form, Input, Switch } from 'antd';
import { InputMask } from '../../components/InputMask';
import { CompanyStore } from './store';
import { cnpj } from 'cpf-cnpj-validator';

type Props = {
  company: Company | null;
  isLoading: boolean;
  createCompany: CompanyStore['createCompany'];
  updateCompany: CompanyStore['updateCompany'];
};

export const CompanyForm: React.FC<Props> = ({ company, isLoading, createCompany, updateCompany }) => {
  const [form] = Form.useForm<Company>();

  useEffect(() => {
    if (company) {
      form.resetFields();
      form.setFieldsValue(company)
    }
  }, []);

  const onFinish = (company: Company) => {
    if (company.id) {
      updateCompany(company);
      return;
    }

    createCompany(company);
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
      >
        <Form.Item
          style={{ display: 'none' }}
          label="ID"
          name="id"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Fantasia"
          name="name"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Razão Social"
          name="legalName"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="CNPJ"
          name="taxpayerNumber"
          rules={[
            {
              required: true,
              validator(_, value) {
                if (cnpj.isValid(value)) return Promise.resolve()
                return Promise.reject('CNPJ inválido');
              }, 
            },
            { required: true, message: 'Campo obrigatório' },
          ]}
        >
          <InputMask mask='00.000.000/0000-00' disabled={!!company?.id} />
        </Form.Item>
        <Form.Item
          label="Subdomínio"
          name="subdomain"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Telefone"
          name="phone"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <InputMask mask="(00) 0000-0000" />
        </Form.Item>
        <Form.Item
          label="Celular"
          name="cellphone"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <InputMask mask="(00) 00000-0000" />
        </Form.Item>
        <Form.Item
          label="Endereço"
          name="address"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Habilitada"
          name="isEnable"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          valuePropName="checked"
          initialValue={true}
        >
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" block htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
