import React from 'react';
import { PageContent } from '../../components/PageContent';
import { PageHeader } from '../../components/PageHeader';
import { UserContainer } from '../../containers/User';

export const UsersPage: React.FC = () => {
  return (
    <>
      <PageContent>
        <>
          <PageHeader title="Usuários" level={2} />
          <UserContainer />
        </>
      </PageContent>
    </>
  );
};
