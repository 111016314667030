import { ModalStaticFunctions } from 'antd/es/modal/confirm';
import { NotificationInstance } from 'antd/es/notification/interface';
import { create } from 'zustand';

type NotificationProps = {
  message: string;
  description?: string;
};

export type GlobalMessageStore = {
  notificationApi: NotificationInstance | null;
  setNotificationApi: (notificationApi: NotificationInstance) => void;
  modalApi: Omit<ModalStaticFunctions, "warn"> | null;
  setModalApi: (modalApi: Omit<ModalStaticFunctions, "warn">) => void;
  successNotification: (data: NotificationProps) => void;
  errorNotification: (data: NotificationProps) => void;
};

export const useGlobalMessageStore = create<GlobalMessageStore>()(
  (set, get) => {
    return {
      notificationApi: null,
      setNotificationApi(notificationApi) {
        set({ notificationApi });
      },
      modalApi: null,
      setModalApi(modalApi) {
        set({ modalApi });
      },
      successNotification({ message, description }) {
        const notificationApi = get().notificationApi;
        if (notificationApi) {
          notificationApi.success({
            message,
            description,
            placement: 'bottomLeft',
          })
        }
      },
      errorNotification({ message, description }) {
        const notificationApi = get().notificationApi;
        if (notificationApi) {
          notificationApi.error({
            message,
            description,
            placement: 'bottomLeft',
          })
        }
      },
    }
  }
);
