import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

type Props = {
  value: string | null;
  onChange: (value: string) => void;
  disabled: boolean;
}

export const InputBudgetAnswerObservations: React.FC<Props> = ({ value, onChange, disabled }) => {
  const [initialValue, setInitialValue] = useState<string>(value || '');
  const [currentValue, setCurrentValue] = useState<string>(value || '');

  useEffect(() => {
    setInitialValue(value || '');
  }, [value]);

  const handleOnBlur = () => {
    if (initialValue !== currentValue) {
      onChange(currentValue);
    }
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value) {
      setCurrentValue(event.target.value);
    }
  }
  return (
    <Input
      size="small"
      rootClassName="navigable"
      defaultValue={currentValue}
      tabIndex={0}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      disabled={disabled}
    />
  );
}