import { Card, Col, Row, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BudgeResultsCheapestItemsBySellers } from './components/BudgeResultsCheapestItemsBySellers';
import { BudgeResultsItemsBySellers } from './components/BudgeResultsItemsBySellers';
import { BudgetStatusTag } from '../Budget/components/BudgetStatusTag';
import { BudgetCloseButton } from '../Budget/components/BudgetCloseButton';
import { useBudgetStore } from '../Budget/store';
import { useCurrentPage } from '../../hooks';

export const BudgetResultsContainer: React.FC = () => {
  const { budgetId } = useParams<{ budgetId: string }>();
  const { getBudget, budget, closeBudget } = useBudgetStore();
  const { navigate, subdomain } = useCurrentPage();

  const onBackButton = () => {
    navigate(`/${subdomain}/budget`)
  };

  useEffect(() => {
    if (budgetId) {
      getBudget(Number(budgetId));
    }
  }, [budgetId, getBudget]);
  
  return (
    <Card>
      <Row>
        <Col>
          <BudgetStatusTag budgetStatus={budget?.status} />
        </Col>
        <Col>
          <BudgetCloseButton budget={budget} onBack={onBackButton} onClose={closeBudget} />
        </Col>
      </Row>
      <Tabs
        tabPosition="top"
        destroyInactiveTabPane
        defaultActiveKey='0'
        items={[
          {
            label: 'Itens vencedores por vendedor',
            key: '0',
            children: <BudgeResultsCheapestItemsBySellers budgetId={budgetId} />
          },
          {
            label: 'Orçamento por vendedor',
            key: '1',
            children: <BudgeResultsItemsBySellers budgetId={budgetId} />
          }
        ]}
      />
    </Card>
  );
};