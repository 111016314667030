import React, { useEffect, useState } from 'react';
import { useAuthStore } from '../Authentication/store';
import { useUserStore } from './store';
import { GetUsersParams } from './types/GetUsersParams';
import { User } from './types/User';
import { UserForm } from './UserForm';
import { UserList } from './UserList';
import { message } from 'antd';

export const UserContainer: React.FC = () => {
  const { currentUser } = useAuthStore();
  const store = useUserStore();
  const { getUsers, users, getUsersLoading, getUsersError, usersPagination } = store;
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [ getUsersParams, setGetUsersParams ] = useState<GetUsersParams>({ limit: 10, page: 1 });
  const [ messageApi, contextHolder ] = message.useMessage();

  const onSelect = (user: User) => {
    setSelectedUser(user);
  };

  const onResetForm = () => {
    setSelectedUser(null);
  };

  useEffect(() => {
    getUsers(getUsersParams);
  }, []);

  useEffect(() => {
    if (getUsersError) {
      messageApi.open({
        type: 'error',
        content: 'Não foi possível buscar os usuários. Tente novamente em instantes.',
        duration: 5,
      })
    }
  }, [getUsersError, messageApi]);

  useEffect(() => {
    getUsers(getUsersParams);
  }, [getUsers, getUsersParams]);
  
  const onPageChange = (page: number) => {
    setGetUsersParams((params) => ({...params, page}))
  };

  const onSearch = (searchTerm: string) => {
    setGetUsersParams((params) => ({...params, searchTerm}))
  }

  return (
    <>
      {contextHolder}
      <UserForm
        currentUserType={currentUser?.type || ''}
        selectedUser={selectedUser}
        onResetForm={() => onResetForm()}
        store={store}
      />
      <UserList
        onSearch={onSearch}
        onPageChange={onPageChange}
        pagination={usersPagination}
        isLoading={getUsersLoading}
        onSelect={onSelect}
        data={users}
      />
    </>
  )
};
