import React from 'react';
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from '../components/AuthProvider';
import { MainLayout } from '../components/MainLayout';
import { SubdomainProvider } from '../components/SubdomainProvider';
import { LoginPage, ForgotPasswordPage, ResetPasswordPage } from '../pages/Authentication';
import { SetupHome, SetupPage } from '../pages/Setup';
import { UsersPage } from '../pages/Users';
import { ProductTemplatesPage } from '../pages/Product';
import { ProductImportPage } from '../pages/Product/ProductImportPage';
import { SellerPage } from '../pages/Seller';
import { BudgetFormPage, BudgetPage } from '../pages/Budget';
import { BudgetFormEditPage } from '../pages/Budget/BudgetFormEditPage';
import { BudgetAnswerPage } from '../pages/BudgetAnswer';
import { BudgetResultsPage } from '../pages/Budget/BudgetResultsPage';
import { CompanyPage } from '../pages/Company';

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/:subdomain">
        <Route element={
          <SubdomainProvider>
            <AuthProvider>
              <MainLayout />
            </AuthProvider>
          </SubdomainProvider>
        }>
          <Route index path="" element={<h1>Home</h1>} />
          <Route index path="users" element={<UsersPage />} />
          <Route path="admin">
            <Route path="company" element={<CompanyPage />} />
          </Route>
          <Route path="setup" element={<SetupPage />}>
            <Route index element={<SetupHome />}></Route>
            <Route index path="users" element={<UsersPage />} />
            <Route path="product">
              <Route index path="template" element={<ProductTemplatesPage />} />
              <Route index path="import" element={<ProductImportPage />} />
            </Route>
            <Route index path="sellers" element={<SellerPage />} />
          </Route>
          <Route path="budget" element={<BudgetPage />} />
          <Route index path="budget/new" element={<BudgetFormPage />} />
          <Route index path="budget/:budgetId/edit" element={<BudgetFormEditPage />} />
          <Route index path="budget/:budgetId/results" element={<BudgetResultsPage />} />

          <Route path="budgetAnswer/:budgetAnswerId" element={<BudgetAnswerPage />} />
          <Route path="*" element={<h1>404</h1>} />
        </Route>

        <Route path="login" element={
          <SubdomainProvider>
            <LoginPage />
          </SubdomainProvider>
        } />

        <Route path="forgot-password" element={
          <SubdomainProvider>
            <ForgotPasswordPage />
          </SubdomainProvider>
        } />

        <Route path="resetPassword" element={
          <SubdomainProvider>
            <ResetPasswordPage />
          </SubdomainProvider>
        } />
      </Route>
    </Routes>
  );
};
