import { AxiosError } from 'axios';
import { create } from 'zustand';
import { useLocalStorage } from '../../../hooks';
import {
  auth,
  AuthRequest,
  AuthResponse,
  PasswordResetRequest,
  requestCurrentUser,
  RequestPasswordResetRequest,
  requestPasswordReset,
  passwordReset,
} from './services';

export type CurrentCompany = {
  id: number;
  name: string;
  legalName: string;
  subdomain: string;
};

export type CurrentUser = {
  id: number;
  taxpayerNumber: string;
  name: string;
  email: string;
  type: string;
  company: CurrentCompany;
};

type AuthState = {
  loadingAuthenticate: boolean;
  loadingCurrentUser: boolean;
  loadingRequestPasswordReset: boolean,
  requestPasswordResetWasSent: boolean,
  loadingPasswordReset: boolean,
  passwordResetWasSent: boolean,
  passwordResetFailed: boolean,
  isPasswordResetExpired: boolean,
  currentUser: CurrentUser | null;
  setCurrentUser: (currentUser: CurrentUser) => void;
  authenticate: (data: AuthRequest) => Promise<AuthResponse>;
  requestCurrentUser: (subdomain: string) => Promise<void>;
  logout: (callbackFunction: () => void) => void;
  requestPasswordReset: (data: RequestPasswordResetRequest) => void;
  passwordReset: (data: PasswordResetRequest) => void;
}

export const useAuthStore = create<AuthState>()(
  (set) => {
    const { setItem, removeItem } = useLocalStorage();
    return {
      loadingAuthenticate: false,
      loadingCurrentUser: false,
      loadingRequestPasswordReset: false,
      requestPasswordResetWasSent: false,
      loadingPasswordReset: false,
      passwordResetWasSent: false,
      passwordResetFailed: false,
      isPasswordResetExpired: false,
      currentUser: null,
      setCurrentUser: (value) => set({ currentUser: value }),
      authenticate: async (data) => {
        try {
          set({ loadingAuthenticate: true });
          const response = await auth(data);
          setItem('auth_token', response.token);
          set({ loadingAuthenticate: false })
          return response;
        } catch (error) {
          set({ loadingAuthenticate: false })
          throw error;
        }
      },
      requestCurrentUser: async (subdomain) => {
        try {
          setItem('subdomain', subdomain);
          set({ loadingCurrentUser: true })
          const currentUser = await requestCurrentUser();
          set({ loadingCurrentUser: false })
          set({ currentUser });
        } catch (error) {
          set({ loadingCurrentUser: true })
          throw error;
        }
      },
      logout: () => {
        set({ currentUser: null });
        removeItem('auth_token');
      },
      requestPasswordReset: async ({ email, subdomain }) => {
        try {
          set({ loadingRequestPasswordReset: true, requestPasswordResetWasSent: false })
          await requestPasswordReset({ email, subdomain });
          set({ loadingRequestPasswordReset: false, requestPasswordResetWasSent: true })
        } catch (error) {
          set({ loadingRequestPasswordReset: true, requestPasswordResetWasSent: false })
          throw error;
        }
      },
      passwordReset: async ({ password, token }) => {
        try {
          set({ loadingPasswordReset: true, passwordResetWasSent: false, isPasswordResetExpired: false })
          await passwordReset({ password, token })
          set({ loadingPasswordReset: false, passwordResetWasSent: true })
        } catch (error) {
          const { response } = error as unknown as AxiosError;
          if (response && response.status === 404) {
            set({ loadingPasswordReset: false, isPasswordResetExpired: true })
          } else {
            set({ loadingPasswordReset: false, passwordResetFailed: true })
          }
        }
      }
    }
  }
);
