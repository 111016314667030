import { RcFile } from 'antd/es/upload';
import { httpClient } from '../../../../helpers/httpClient';
const authClient = httpClient();

export const importProducts = async (
  { templateId, file }: { templateId: number, file: RcFile }
) => {
  const form = new FormData();
  form.append('templateId', String(templateId));
  form.append('file', file);
  const response = await authClient()({
    url: '/product/import',
    method: 'post',
    data: form,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return response.data;
};