import { Button, Card, Checkbox, Col, Form, Input, message, Row, Select, Switch } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { PageHeader } from '../../components/PageHeader';
import { UserStore } from './store';
import { User } from './types/User';
import { UserTypesEnum } from './UserTypesEnum';
import { cpfRule } from '../../helpers';
import { InputMask } from '../../components/InputMask';

const createUserTypeOptions = (currentUserType: string) => {
  const options = [
    { value: UserTypesEnum.USER, label: 'Usuário' },
  ];

  if (currentUserType === UserTypesEnum.ROOT) {
    options.push({ value: UserTypesEnum.OWNER, label: 'Owner' });
    options.push({ value: UserTypesEnum.ROOT, label: 'Root' },);
  }

  return options;
};

type UserFormProps = {
  currentUserType: string;
  selectedUser: User | null;
  onResetForm: () => void;
  store: UserStore,
};

export const UserForm: React.FC<UserFormProps> = ({ currentUserType, selectedUser, onResetForm, store }) => {
  const [form] = Form.useForm<User>();
  const [messageApi, contextHolder] = message.useMessage();

  const { saveUserIsLoading, updateUserIsLoading } = store;

  const onSaveSuccess = () => {
    messageApi.open({
      type: 'success',
      content: 'Usuário criado com sucesso!',
      duration: 5,
    });
    resetForm();
  }

  const onSaveError = () =>
    messageApi.open({
      type: 'error',
      content: 'Tivemos um problema ao criar o usuário. Tente novamente em instantes.',
      duration: 5,
    });

  useEffect(() => {
    if (selectedUser) {
      form.resetFields();
      form.setFieldsValue(selectedUser);
    }
  }, [form, selectedUser]);

  const resetForm = useCallback(() => {
    form.resetFields();
    onResetForm();
  }, [form, onResetForm])

  const onFinish = (user: User) => {
    if (!user.id) {
      store.saveUser(user, onSaveSuccess, onSaveError);
      return;
    }

    store.updateUser({
      user,
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'Usuário atualizado com sucesso!',
          duration: 5,
        });
        resetForm();
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: 'Tivemos um problema ao editar o usuário. Tente novamente em instantes.',
          duration: 5,
        })
      }
    })
  }


  return (
    <>
      {contextHolder}
      <PageHeader subtitle="Cadastro de usuários na plataforma" level={5} />
      <Card>
        <Form form={form} onFinish={onFinish}>
          <Row>
            <Col span={1}>
              <Form.Item
                style={{ display: 'none' }}
                label="ID"
                name="id"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 23 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item
                label="Nome"
                name="name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 23 }}
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input status="" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label="E-mail"
                name="email"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 23 }}
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input disabled={!!selectedUser?.id} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label="CPF"
                name="taxpayerNumber"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 23 }}
                rules={[
                  { required: true, message: 'Campo obrigatório' },
                  cpfRule('CPF inválido'),
                ]}
              >
                <InputMask mask="000.000.000-00" disabled={!!selectedUser?.id} />
              </Form.Item>
            </Col>
            {
              currentUserType === UserTypesEnum.ROOT && (
                <Col span={5}>
                  <Form.Item
                    label="Tipo"
                    name="type"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 23 }}
                    initialValue="user"
                    rules={[{ required: true, message: 'Campo obrigatório' }]}>
                    <Select
                      status=""
                      value="user"
                      options={createUserTypeOptions(currentUserType)}
                    />
                  </Form.Item>
                </Col>
              )
            }
            {
              selectedUser && (
                <Col span={3}>
                  <Form.Item
                    label="Habilitado"
                    name="isEnable"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    valuePropName="checked"
                  >
                    <Switch defaultChecked />
                  </Form.Item>
                </Col>
              )
            }
          </Row>
          <Row>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={saveUserIsLoading || updateUserIsLoading}>
                Salvar
              </Button>
              <Button htmlType="button" onClick={() => resetForm()} loading={saveUserIsLoading || updateUserIsLoading}>
                Cancelar
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Card>
    </>
  );
};
