import React from 'react';
import { Layout, Row, Col, Spin } from 'antd';

export const LoadingPage: React.FC = () => {
  
  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Layout.Content>
          <Row align="middle">
            <Col span={11} style={{ textAlign: 'center', height: "100vh" }} />
            <Col span={2}>
              <Spin tip="Carregando..." size="large" />
            </Col>
            <Col span={11} style={{ textAlign: 'center', height: "100vh" }} />
          </Row>
        </Layout.Content>
      </Layout>
    </>
  );
};
