import React, { useEffect, useMemo } from 'react';
import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Result, Spin } from 'antd';
import Typography from 'antd/lib/typography';
import { Budget } from '../types/Budget';
import { BudgetItem } from '../types/BudgetItem';
import { BudgetItemStore } from '../store';
import { BudgetStepsEnum } from '../types/BudgetStepsEnum';
import { BudgetStatus } from '../types/BudgetStatus';

const { Paragraph, Text } = Typography;

type Props = {
  budget: Budget | null;
  budgetItems: BudgetItem[];
  isLoading: boolean;
  setBudgetId: BudgetItemStore['setBudgetId'];
  onChangeStep: (current: BudgetStepsEnum) => void;
  onClickPublish: () => void;
  onClickBackButton: () => void;
}

export const BudgetPublish: React.FC<Props> = ({
  budget,
  budgetItems = [],
  isLoading,
  setBudgetId,
  onChangeStep,
  onClickPublish,
  onClickBackButton,
}) => {
  const hasWarnings = useMemo(() => {
    if (budget) {
      return !budget.companySellerIds.length || !budgetItems.length
    }
    return false;
  }, [budget, budgetItems.length]);

  useEffect(() => {
    if(budget?.id) {
      setBudgetId(budget.id);
    }
  }, []);
  
  return (
    <>
      {
        (hasWarnings && !isLoading) && (
          <Result
            status="warning"
            title="Existem itens que você precisar dar atenção antes de publicar"
          >
            <div className="desc">
              <Paragraph>
                <Text
                  strong
                  style={{
                    fontSize: 16,
                  }}
                >
                  Pontos de atenção:
                </Text>
              </Paragraph>
              {
                !budget?.companySellerIds.length && (
                  <Paragraph>
                    <CloseCircleOutlined className="site-result-demo-error-icon" /> Você precisa selecionar pelo menos um vendedor.
                    <Button type="link" size="small" onClick={() => onChangeStep(BudgetStepsEnum.budgetData)}>Revisar</Button>
                  </Paragraph>
                )
              }
              {
                !budgetItems.length && (
                  <Paragraph>
                    <CloseCircleOutlined className="site-result-demo-error-icon" /> Você precisa adicionar pelo menos um item ao orçamento.
                    <Button type="link" size="small" onClick={() => onChangeStep(BudgetStepsEnum.budgeItems)}>Revisar</Button>
                  </Paragraph>
                )
              }
            </div>
          </Result>
        )
      }
      {
        (!hasWarnings && budget?.status === BudgetStatus.draft) && (
          <Result
            status="info"
            {...(isLoading ? { icon: <Spin indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />} size="large" /> } : {}) }
            title="Seu orçamento está pronto para publicação"
            subTitle="Ao publicar, cada vendedor envolvido no orçamento receberá um email com o link para responder."
            extra={[
              <Popconfirm
                key="publish"
                placement="top"
                title="Tem certeza que quer publicar o orçamento?"
                description="Após a publicação não será mais possível fazer alterações."
                okText="Sim"
                cancelText="Cancelar"
                onConfirm={() => onClickPublish()}
              >
                <Button type="primary">
                  Publicar agora
                </Button>
              </Popconfirm>,
              <Button key="back" onClick={onClickBackButton}>Sair</Button>,
            ]}
          />
        )
      }
      {
        (!hasWarnings && budget?.status === BudgetStatus.published) && (
          <Result
            status="success"
            title="Seu orçamento está publicado"
            subTitle="Agora é só aguardar os vendedores responderem!"
            extra={[
              <Button key="back" onClick={onClickBackButton}>Sair</Button>,
            ]}
          />
        )
      }
    </>
  );
}