import { httpClient } from '../../../../helpers/httpClient';
import { PaginatedProductTemplate } from '../types/PaginatedProductTemplate';
import { ProductTemplate } from '../types/ProductTemplate';

const authClient = httpClient();

export const getProductTemplates = async (
  data: { searchTerm?: string, page: number, limit: number }
) => {
  if (!data.searchTerm) delete data.searchTerm;
  const params = new URLSearchParams(data as Record<string, any>);
  const response = await authClient().get<PaginatedProductTemplate>(`/product/template?${params.toString()}`);
  return response.data;
};

export const createProductTemplate = async (
  data: ProductTemplate
) => {
  const response = await authClient().post<PaginatedProductTemplate>('/product/template', data);
  return response.data;
};

export const editProductTemplate = async (
  data: ProductTemplate
) => {
  const response = await authClient().put<PaginatedProductTemplate>('/product/template', data);
  return response.data;
};
