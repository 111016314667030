import { Col, Row } from 'antd';
import React from 'react';
import { PageContent } from '../../components/PageContent';
import { PageHeader } from '../../components/PageHeader';

export const SetupHome: React.FC = () => {
  return (
    <PageContent backgoundColor="#c4e1f1">
      <Row style={{ marginTop: '10%' }}>
        <Col span={24} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
          <img
            style={{ borderRadius: '25%' }}
            width="40%"
            src={`${process.env.PUBLIC_URL}/assets/setup-image.jpg`}
            alt="logo"
          />
        </Col>
        <Col span={24} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
          <PageHeader title="Setup da empresa" subtitle="No setup é possível configurar dados da sua empresa." />
        </Col>
      </Row>
    </PageContent>
  )
};