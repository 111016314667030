import React, { useState } from 'react';
import { Button, Checkbox, Col, Drawer, Row, Typography } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import { BudgetStatus } from '../types/BudgetStatus';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useFilterParams } from '../hooks/useFilterParams';

type Props = {
  isOpen: boolean;
  onClose: () => void;
}

const options = [
  { label: 'Rascunho', value: BudgetStatus.draft },
  { label: 'Publicado', value: BudgetStatus.published },
  { label: 'Encerrado', value: BudgetStatus.closed },
];

export const BudgetFilter: React.FC<Props> = ({ isOpen, onClose }) => {
  const { filters, setFilters } = useFilterParams();
  const [status, setStatus] = useState<BudgetStatus[]>(filters.status);

  const handleOnChangeStatus = (values: CheckboxValueType[]) => {
    setStatus(values as unknown as BudgetStatus[]);
  };

  const handleOnFinish = () => {
    setFilters({
      status,
    });
    onClose();
  };

  return (
    <Drawer title="Filtros" placement="right" onClose={onClose} open={isOpen} destroyOnClose>
      <div style={{ height: '93%' }}>
        <Typography.Title style={{ marginTop: '0' }} level={5}>Status atual do orçamento</Typography.Title>
        <Checkbox.Group style={{ width: '100%' }} onChange={handleOnChangeStatus} defaultValue={filters.status}>
          <Row>
            {
              options.map((option, id) => (
                <Col span={24} key={id} style={{ paddingBottom: '10px' }}>
                  <Checkbox value={option.value} checked>{option.label}</Checkbox>
                </Col>
              ))
            }
          </Row>
        </Checkbox.Group>
      </div>
      <Footer style={{ textAlign: 'center' }}>
        <Button block size="large" type="primary" onClick={handleOnFinish}>
          Aplicar filtros selecionados
        </Button>
      </Footer>
    </Drawer>
  );
};