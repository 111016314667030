import { HomeOutlined, UserOutlined, BuildOutlined, LogoutOutlined, ShopOutlined, SafetyOutlined, FlagOutlined, SettingOutlined, BarsOutlined } from '@ant-design/icons';
import { Avatar, Card, Col, Row, Space, Typography } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { CurrentUser } from '../containers/Authentication/store';

type CreateMenuProps = {
  subdomain: string;
  userType: string | undefined;
  currentUser: CurrentUser | null;
}

const getNameInitials = (name: string | undefined) => {
  if (!name) return '';
  const chunks = name.split(' ');
  return chunks.map(chunk => chunk[0].toUpperCase()).join('');
}; 

export const createMenuItems = ({ subdomain, userType, currentUser }: CreateMenuProps): ItemType[] => {
  const isSeller = !userType;
  
  const home: ItemType = {
    label: 'Home',
    key: `/${subdomain}`,
    icon: <HomeOutlined />,
  };

  const budget: ItemType = {
    label: 'Gestão de orçamentos',
    key: `/${subdomain}/budget`,
    icon: <BarsOutlined />,
  };

  const companies: ItemType = {
    label: 'Admin',
    key: `AdminMenu`,
    icon: <SafetyOutlined />,
    theme: 'light',
    children: [
      {
        label: 'Empresas',
        key: `/${subdomain}/admin/company`,
        icon: <ShopOutlined />,
      },
      {
        label: 'Feature Flags',
        key: `/${subdomain}/admin/feature-flags`,
        icon: <FlagOutlined />,
      }
    ]
  };

  const childrenLoggedMenu: ItemType[] = [
    {
      label: (
        <>
          <Space.Compact direction="vertical">
            <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{currentUser?.name}</Typography.Title>
            <Typography.Text style={{ marginTop: 0, marginBottom: 0 }} type="secondary">{currentUser?.type}</Typography.Text>
          </Space.Compact>
        </>
      ),
      style: { height: '60px' },
      key: 'teste',
      type: 'group',
    },
  ];

  if (!isSeller) {
    childrenLoggedMenu.push({ type: 'divider' })
    childrenLoggedMenu.push({
      label: 'Setup',
      icon: <SettingOutlined />,
      key: 'setup',
    })

  }

  childrenLoggedMenu.push({ type: 'divider' })

  childrenLoggedMenu.push({
    label: 'Sair',
    icon: <LogoutOutlined />,
    key: 'login',
  })

  const loggedUserMenu: ItemType = {
    key: `UserProfileSubmenu`,
    theme: 'light',
    label: (
      <Avatar
        size="large"
        style={{ backgroundColor: '#6971b5' }}
      >
        {getNameInitials(currentUser?.name)}
      </Avatar>
    ),
    children: childrenLoggedMenu,
  };

  const items : ItemType[] = [];

  if (!isSeller) {
    items.push(home);
    items.push(budget);
    if (userType === 'root') {
      items.push(companies);
    }
    items.push(loggedUserMenu);
  }

  if (isSeller) {
    items.push(loggedUserMenu);
  }

  return items;
}