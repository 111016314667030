import React from 'react';
import { PageContent } from '../../components/PageContent';
import { PageHeader } from '../../components/PageHeader';
import { BudgetAnswerContainer } from '../../containers/BudgetAnswer';

export const BudgetAnswerPage: React.FC = () => {
  return (
    <>
      <PageContent>
        <>
          <PageHeader
            title="Resposta de orçamento"
            level={2}
          />
          <BudgetAnswerContainer />
        </>
      </PageContent>
    </>
  );
};