import { useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

type UseCustomPageResponse = {
  currentPage: string,
  subdomain: string,
  searchParams: URLSearchParams;
  navigate: (to: string) => void;
}

export const useCurrentPage = (): UseCustomPageResponse => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { subdomain = '' } = useParams();
  const currentPage = pathname.replace(/\/$/gm, '')
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  return {
    currentPage,
    subdomain,
    searchParams,
    navigate: (to) => navigate(to, { replace: false })
  }
} 