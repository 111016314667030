import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, List, Popconfirm, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { Pagination } from '../../../types/Pagination';
import { BudgetItemStore } from '../store';
import { BudgetItem } from '../types/BudgetItem';
import { BudgetStatus } from '../types/BudgetStatus';

type Props = {
  budgetItems: BudgetItem[];
  deleteBudgetItem: BudgetItemStore['deleteBudgetItem'];
  isLoading: boolean;
  pagination: Pagination;
  setPagination: BudgetItemStore['setPagination'];
  onNewItemClick: () => void;
  budgetStatus?: BudgetStatus;
}

export const BudgetItemsList: React.FC<Props> = ({
  budgetItems,
  deleteBudgetItem,
  isLoading,
  pagination,
  setPagination,
  onNewItemClick,
  budgetStatus,
}) => {
  const { limit, total } = pagination;
  
  const columns: ColumnsType<BudgetItem> = [
    {
      title: 'Item',
      responsive: ['xs'],
      render(value, record, index) {
        return (
          <List.Item>
            <List.Item.Meta
              title={`${record.code} - ${record.description}`}
            />
            <Space direction="vertical">
              <span>Obs.: {record.observation}</span>
              <span>Qtd.: {record.quantity} {record.metricUnit}</span>
            </Space>
          </List.Item>
        )
      },
    },
    { title: 'Código', dataIndex: 'code', key: 'code', responsive: ['md'] },
    { title: 'Cód. Barras', dataIndex: 'barcode', key: 'barcode', responsive: ['md'] },
    { title: 'Descrição', dataIndex: 'description', key: 'description', responsive: ['md'] },
    { title: 'Unid. Medida', dataIndex: 'metricUnit', key: 'metricUnit', responsive: ['md'] },
    { title: 'Quantidade', dataIndex: 'quantity', key: 'quantity', responsive: ['md'] },
    { title: 'Obs.', dataIndex: 'observation', key: 'observation', responsive: ['md'] },
    {
      title: 'Remover',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space size="middle" wrap>
            <Popconfirm
              placement="topLeft"
              title="Tem certeza que quer deletar o item?"
              description="Essa ação é irreversível"
              onConfirm={() => onDeleteHandle(record.id)}
              okText="Sim"
              cancelText="Cancelar"
              disabled={budgetStatus !== BudgetStatus.draft}
            >
              <Button type="link" size="small" title="Remover item" disabled={budgetStatus !== BudgetStatus.draft}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        )
      },
    }
  ];

  const onDeleteHandle = (id?: number) => {
    if(id) {
      deleteBudgetItem(id);
    }
  }
  return (
    <Card
      title="Itens do orçamento"
      bodyStyle={{ padding: 0 }}
      extra={
        <>
          <Button type="primary" onClick={onNewItemClick} disabled={budgetStatus !== BudgetStatus.draft}>
            <PlusOutlined /> Adicionar item
          </Button>
        </>
      }
    >
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={budgetItems}
        rowKey="id"
        locale={{ emptyText: 'Nenhum item adicionado ao orçamento' }}
        pagination={{
          total: total,
          pageSize: limit,
          position: ['bottomRight'],
          onChange: (page) => setPagination({ page }),
        }}
      />
    </Card>
  );
}