import { create } from 'zustand';
import { useGlobalMessageStore } from '../../../../components/GlobalMessageProvider/store';
import { Pagination } from '../../../../types/Pagination';
import { Product } from '../types/Product';
import { getProducts } from './services';

export type ProductStore = {
  init: () => void;
  isLoading: boolean;
  products: Product[];
  productsPagination: Pagination;
  setPagination: (pagination: Partial<Pagination>, options?: { searchOnChange?: boolean }) => void;
  getProducts: () => void;
  searchTerm: string | undefined;
  setSearchTerm: (searchTerm: string) => void;
};

export const useProductStore = create<ProductStore>()(
  (set, get) => {
    return {
      init() {
        set({ products: [] })
      },
      searchTerm: undefined,
      setSearchTerm(searchTerm) {
        if (searchTerm) {
          set({ searchTerm })
          get().getProducts();
        }
      },
      isLoading: false,
      products: [],
      productsPagination: { page: 1, pages: 1, limit: 10, total: 1 },
      setPagination({ page, limit }, options = { searchOnChange: false }) {
        const searchTerm = get().searchTerm;
        const productsPagination = get().productsPagination;
        if (page) set({ productsPagination: { ...productsPagination, page } });
        if (limit) set({ productsPagination: { ...productsPagination, limit } });

        if (searchTerm && options.searchOnChange) {
          get().getProducts();
        }
      },
      async getProducts() {
        try {
          set({ isLoading: true });
          const { page, limit } = get().productsPagination;
          const searchTerm = get().searchTerm;
          const response = await getProducts({ page, limit, searchTerm });
          const { data, ...pagination } = response;
          set({ products: data, productsPagination: pagination, isLoading: false });
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível buscar os produtos. Tente novamente em instantes.'
          });
        }
      },
    }
  }
);
