import { Drawer } from 'antd';
import React, { useEffect } from 'react';
import { Pagination } from '../../../types/Pagination';
import { BudgetItemStore } from '../store';
import { Budget } from '../types/Budget';
import { BudgetItem } from '../types/BudgetItem';
import { BudgetItemsForm } from './BudgetItemsForm';
import { BudgetItemsList } from './BudgetItemsList';

type Props = {
  budgetItems: BudgetItem[];
  budget: Budget | null;
  setBudgetId: BudgetItemStore['setBudgetId'];
  createBudgetItem: BudgetItemStore['createBudgetItem'];
  deleteBudgetItem: BudgetItemStore['deleteBudgetItem'];
  isLoading: boolean;
  pagination: Pagination;
  setPagination: BudgetItemStore['setPagination'];
  openCreateItemDrawer: BudgetItemStore['openCreateItemDrawer'];
  closeCreateItemDrawer: BudgetItemStore['closeCreateItemDrawer'];
  isCreateItemDrawerOpened: boolean;
};

export const BudgetItems: React.FC<Props> = ({
  budgetItems,
  budget,
  setBudgetId,
  createBudgetItem,
  deleteBudgetItem,
  isLoading,
  pagination,
  setPagination,
  isCreateItemDrawerOpened,
  openCreateItemDrawer,
  closeCreateItemDrawer,
}) => {

  useEffect(() => {
    if (budget?.id) {
      setBudgetId(budget.id);
    }
  }, []);
  
  return (
    <>
      <BudgetItemsList
        budgetItems={budgetItems}
        deleteBudgetItem={deleteBudgetItem}
        isLoading={isLoading}
        pagination={pagination}
        setPagination={setPagination}
        onNewItemClick={openCreateItemDrawer}
        budgetStatus={budget?.status}
      />

      <Drawer
        title="Adicionar item ao orçamento"
        placement="right"
        closable
        onClose={closeCreateItemDrawer}
        open={isCreateItemDrawerOpened}
        destroyOnClose
      >
        <BudgetItemsForm
          createBudgetItem={createBudgetItem}
          isLoading={isLoading}
        />
      </Drawer>
    </>
  );
};
