import React, { useEffect } from 'react';
import { useAuthStore } from '../containers/Authentication/store';
import { useCurrentPage, useLocalStorage } from '../hooks';
import { LoadingPage } from './LoadingPage';

type Props = {
  children: React.ReactElement;
};

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const { getItem, setItem } = useLocalStorage();
  const { navigate, subdomain, searchParams } = useCurrentPage();
  const { requestCurrentUser, currentUser, logout, loadingCurrentUser } = useAuthStore();

  const sellerToken = searchParams.get('token');

  if (sellerToken) {
    setItem('auth_token', sellerToken);
  }

  const token = getItem('auth_token');
  

  useEffect(() => {
    if (!token) {
      logout(() => navigate(`/${subdomain}/login`));
    }
    requestCurrentUser(subdomain);
  }, []);

  useEffect(() => {
    if(currentUser && subdomain !== currentUser.company.subdomain) {
      logout(() => navigate(`/${subdomain}/login`));
    }
  }, [currentUser, logout, navigate, subdomain]);


  return (loadingCurrentUser) ? <LoadingPage /> : children;
};