import { CurrentUser } from '.';
import { httpClient } from '../../../helpers/httpClient';

const unauthClient = httpClient({ ignoreRedirect: true });
const authClient = httpClient();

export type AuthResponse = {
  token: string;
}

export type AuthRequest = {
  email: string;
  password: string;
  subdomain: string;
}

export type RequestPasswordResetRequest = {
  email: string;
  subdomain: string;
}

export type PasswordResetRequest = {
  password: string;
  token: string;
}

export const auth = async ({ email, password, subdomain }: AuthRequest) => {
  try {
    const response = await unauthClient().post<AuthResponse>('/auth', {
      email,
      password,
      subdomain,
    });
    return response.data
  } catch (error) {
    throw error;
  }
};

export const requestCurrentUser = async () => {
  try {
    const response = await authClient({ statusCodesToRedirect: [401, 403] }).get<CurrentUser>('/auth/current');
    return response.data
  } catch (error) {
    throw error;
  }
};

export const requestPasswordReset = async (data: RequestPasswordResetRequest) => {
  try {
    await unauthClient().post<void>('/auth/request-password-reset', {
      data,
    });
  } catch (error) {
    throw error;
  }
};

export const passwordReset = async (data: PasswordResetRequest) => {
  try {
    await unauthClient().post<void>('/auth/password-reset', {
      data,
    });
  } catch (error) {
    throw error;
  }
};