import { cpf } from 'cpf-cnpj-validator';

export const cpfRule = (message: string) => {
  return {
    message,
    validator: (_: any, value: string) => {
      if (!value) return Promise.resolve();
      if (cpf.isValid(value.replace(/\D/g, ''))) return Promise.resolve();
      return Promise.reject(message);
    },
  }
};
