
type UseLocalStorage = {
  setItem: (key: string, value: any) => void;
  removeItem: (key: string) => void;
  getItem: <Type>(key: string) => Type;
}

export const useLocalStorage = (): UseLocalStorage=> {
  return {
    setItem(key, value) {
      window.localStorage.setItem(key, JSON.stringify(value));
    },
    getItem(key) {
      return JSON.parse(window.localStorage.getItem(key) || 'null');
    },
    removeItem(key) {
      window.localStorage.removeItem(key);
    },
  }
} 