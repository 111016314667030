import React from 'react';
import { Company } from './types/Company';
import { Pagination } from '../../types/Pagination';
import { CompanyStore } from './store';
import Table, { ColumnsType } from 'antd/es/table';
import { Button, Card, Col, Input, Row, Space, Switch } from 'antd';
import { cnpj } from 'cpf-cnpj-validator';
import { LinkOutlined, PlusOutlined } from '@ant-design/icons';

type Props = {
  companies: Company[];
  isLoading: boolean;
  pagination: Pagination;
  getCompanies: CompanyStore['getCompanies'],
  setPagination: CompanyStore['setPagination'],
  setCurrentCompany: CompanyStore['setCurrentCompany'],
};

export const CompanyList: React.FC<Props> = ({
  companies,
  isLoading,
  pagination,
  setPagination,
  getCompanies,
  setCurrentCompany,
}) => {
  const handleClickSubdomain = (subdomain: string) => {
    const url = `${window.location.origin}/${subdomain}`;
    window.open(url, '_blank');
  };

  const columns: ColumnsType<Company> = [
    { title: '#', dataIndex: 'id', key: 'id' },
    { title: 'Fantasia', dataIndex: 'name', key: 'name' },
    { title: 'Razão Social', dataIndex: 'legalName', key: 'legalName' },
    {
      title: 'Subdomínio',
      dataIndex: 'subdomain',
      key: 'subdomain',
      render(subdomain) {
        return (
          <Button size="small" type="link" onClick={() => handleClickSubdomain(subdomain)}>
            {subdomain}
            <LinkOutlined />
          </Button>
        );
      },
    },
    { title: 'CNPJ', dataIndex: 'taxpayerNumber', key: 'taxpayerNumber', render: (value) => cnpj.format(value) },
    {
      title: 'Habilitada',
      dataIndex: 'isEnable',
      key: 'isEnable',
      render(value) {
        return (
          <Space size="middle" direction="horizontal" align="center" wrap>
            <Switch checked={value} disabled size="small" />
          </Space>
        )
      },
    },
    {
      title: 'Ações',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => {
        return (
          <Space size="middle" wrap>
            <Button type="primary" size="small" onClick={() => setCurrentCompany(record)}>
              Editar
            </Button>
          </Space>
        )
      },
    },
  ];
  return (
    <>
      <Row style={{ paddingBottom: '5px' }}>
        <Col span={2} sm={2} offset={22}>
          <Button block icon={<PlusOutlined />} onClick={() => setCurrentCompany(null)}>Novo</Button>
        </Col>
      </Row>
      <Card>
        <Input.Search placeholder="Filtrar por fantasia, razão social ou CNPJ" onSearch={(value) => getCompanies({ searchTerm: value })} allowClear loading={isLoading} />
        <Table
          size="small"
          columns={columns}
          dataSource={companies}
          rowKey="id"
          loading={isLoading}
          pagination={{ total: pagination.total, pageSize: pagination.limit, showSizeChanger: false, onChange: (page) => setPagination({ page }) }}
          locale={{ emptyText: 'Nenhum resultado encontrado' }}
        />
      </Card>
    </>
  );
};
