import React, { useEffect, useMemo } from 'react';
import { useCompanyStore } from './store';
import { CompanyList } from './CompanyList';
import { Drawer } from 'antd';
import { CompanyForm } from './CompanyForm';

export const CompanyContainer: React.FC = () => {
  const {
    companies,
    getCompanies,
    isLoading,
    init,
    pagination,
    setPagination,
    setCurrentCompany,
    isModalOpened,
    setIsModalOpened,
    selectedCompany,
    createCompany,
    updateCompany,
  } = useCompanyStore();
  
  useEffect(() => {
    init();
    getCompanies({ searchTerm: '' });
  }, []);

  const drawerTitle = useMemo(() => {
    return !selectedCompany ? 'Criar empresa' : 'Editar Empresa'
  }, [selectedCompany])

  return (
    <>
      <CompanyList
        companies={companies}
        getCompanies={getCompanies}
        isLoading={isLoading}
        pagination={pagination}
        setPagination={setPagination}
        setCurrentCompany={setCurrentCompany}
      />
      <Drawer
        destroyOnClose
        open={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        title={drawerTitle}
      >
        <CompanyForm
          company={selectedCompany}
          isLoading={isLoading}
          createCompany={createCompany}
          updateCompany={updateCompany}
        />
      </Drawer>
    </>
  );
};
