import { create } from 'zustand';
import { ProductTemplate } from '../types/ProductTemplate';
import { Pagination } from '../../../../types/Pagination';
import { getProductTemplates, createProductTemplate, editProductTemplate } from './services';

export type ProductTemplateStore = {
  templates: ProductTemplate[];
  getTemplatesIsLoading: boolean;
  getTemplatesHasError: boolean;
  templatesPagination: Pagination;
  getTemplates: (
    { searchTerm, page, limit }: { searchTerm: string, page: number, limit: number }
  ) => Promise<void>;
  isSaving: boolean;
  saveComplete: boolean;
  saveError: boolean;
  saveTemplate: (template: ProductTemplate) => Promise<void>;
  resetSaveProps: () => void;
};

export const useProductTemplateStore = create<ProductTemplateStore>()(
  (set) => {
    return {
      templates: [],
      getTemplatesIsLoading: false,
      getTemplatesHasError: false,
      templatesPagination: { page: 1, limit: 10, pages: 1, total: 1 },
      getTemplates: async ({ searchTerm, limit, page }) => {
        try {
          set({ getTemplatesIsLoading: true, getTemplatesHasError: false })
          const response = await getProductTemplates({ searchTerm, limit, page });
          set({
            templates: response.data,
            getTemplatesIsLoading: false,
            templatesPagination: { page: response.page, limit: response.limit, pages: response.pages, total: response.total }
          })
        } catch (error) {
          console.error(error);
          set({ getTemplatesHasError: true, getTemplatesIsLoading: false })
        }
      },
      isSaving: false,
      saveComplete: false,
      saveError: false,
      saveTemplate: async (template) => {
        try {
          set({ isSaving: true, saveComplete: false, saveError: false });
          if (template.id) {
            await editProductTemplate(template);
          } else {
            await createProductTemplate(template);
          }
          set({ isSaving: false, saveComplete: true, saveError: false });
        } catch (error) {
          console.error(error);
          set({ isSaving: false, saveComplete: false, saveError: true });
        }
      },
      resetSaveProps: () => {
        set({ isSaving: false, saveComplete: false, saveError: false });
      },
    }
  }
);
