import { Button, Card, Input, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { Pagination } from '../../types/Pagination';
import { User } from './types/User';
import { UserTypesEnum } from './UserTypesEnum';
import { cpf } from 'cpf-cnpj-validator';

type UserListProps = {
  data: User[];
  onSelect: (user: User) => void;
  onSearch: (searchTerm: string) => void;
  isLoading: boolean;
  pagination: Pagination,
  onPageChange: (page: number) => void;
};

const renderTagType = (userType: UserTypesEnum) => {
  const userTypeColors = {
    [UserTypesEnum.OWNER]: 'geekblue',
    [UserTypesEnum.ROOT]: 'gold',
    [UserTypesEnum.USER]: 'green',
  };

  return (
    <Tag color={userTypeColors[userType]}>
      {userType}
    </Tag>
  );
}

const renderTagStatus = (isEnable: boolean) => {
  return (
    <Tag color={isEnable ? 'green' : 'red'}>
      { isEnable ? 'Habilitado': 'Desabilitado' }
    </Tag>
  );
}

export const UserList: React.FC<UserListProps> = ({ data, onSelect, isLoading, pagination, onPageChange, onSearch }) => {

  const columns: ColumnsType<User> = [
    { title: '#', dataIndex: 'id', key: 'id' },
    { title: 'Nome', dataIndex: 'name', key: 'name' },
    { title: 'E-mail', dataIndex: 'email', key: 'email' },
    {
      title: 'CPF',
      dataIndex: 'taxpayerNumber',
      key: 'taxpayerNumber',
      render: (text: string) => cpf.format(text),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (text: UserTypesEnum) => renderTagType(text),
    },
    {
      title: 'Status',
      dataIndex: 'isEnable',
      key: 'isEnable',
      render: (isEnable: boolean) => renderTagStatus(isEnable),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space size="middle" wrap>
            <Button type="primary" size="small" onClick={() => onSelect(record)}>
              Editar
            </Button>
          </Space>
        )
      },
    }
  ];

  return (
    <>
      <Card>
        <Input.Search placeholder="Filtrar por nome ou email" onSearch={onSearch} allowClear/>
        <Table
          columns={columns}
          dataSource={data}
          rowKey="id"
          loading={isLoading}
          pagination={{ total: pagination.total, pageSize: pagination.limit, showSizeChanger: false, onChange: onPageChange }}
          locale={{ emptyText: 'Nenhum resultado encontrado' }}
        />
      </Card>
    </>
  );
};
