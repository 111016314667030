import { httpClient } from '../../../../helpers/httpClient';
import { PaginatedData } from '../../../../types/PaginatedData';
import { Budget } from '../../types/Budget';
import { BudgetStatus } from '../../types/BudgetStatus';
import queryString from 'query-string';

const authClient = httpClient();

type GetBudgetsParams = {
  searchTerm?: string;
  page: number;
  limit: number;
  status?: BudgetStatus[];
};

export const getBudget = async ({ budgetId }: { budgetId: number }) => {
  const response = await authClient().get<Budget>(`/budget/${budgetId}`)
  return response.data;
};

export const getBudgets = async (data: GetBudgetsParams) => {
  if (!data.searchTerm) delete data.searchTerm;
  if (data.status && !data.status.length) delete data.status;
  const params = queryString.stringify(data, { arrayFormat: 'bracket' });
  const response = await authClient().get<PaginatedData<Budget>>(`/budget?${params}`)
  return response.data;
};

export const createBudget = async (budget: Budget) => {
  const response = await authClient().post<Budget>('/budget', budget);
  return response.data;
};

export const updateBudget = async (budget: Budget) => {
  const response = await authClient().put<Budget>('/budget', budget);
  return response.data;
};

export const publish = async (budgetId: number) => {
  await authClient().post('/budget/publish', { budgetId });
};

export const close = async (budgetId: number) => {
  await authClient().patch(`/budget/${budgetId}/close`);
};
