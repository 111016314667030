import { httpClient } from '../../../../helpers/httpClient';
import { PaginatedData } from '../../../../types/PaginatedData';
import queryString from 'query-string';
import { Product } from '../types/Product';

const authClient = httpClient();

export const getProducts = async (
  data: { page: number; limit: number; searchTerm?: string }
) => {
  if (!data.searchTerm) delete data.searchTerm;
  const params = queryString.stringify(data, { arrayFormat: 'bracket' });
  const response = await authClient().get<PaginatedData<Product>>(`/product?${params}`);
  return response.data;
};