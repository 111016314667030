import { Button, Card, Input, Space, Switch } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { Pagination } from '../../types/Pagination';
import { Seller } from './types/Seller';

type Props = {
  sellers: Seller[];
  getSellers: (
    { searchTerm, page, limit }:
    { searchTerm: string; page: number; limit: number }
  ) => void;
  pagination: Pagination;
  onSelect: (seller: Seller) => void;
  isLoading: boolean;
};

export const SellerList: React.FC<Props> = ({ sellers, getSellers, pagination, onSelect, isLoading }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { page, limit } = pagination;

  useEffect(() => {
    getSellers({ searchTerm: '', page, limit })
  }, []);

  const onSearch = (value: string) => {
    setSearchTerm(value);
    getSellers({ searchTerm: value, page, limit })
  };

  const onPageChange = (page: number) => {
    getSellers({ searchTerm, page, limit })
  };

  const columns: ColumnsType<Seller> = [
    { title: '#', dataIndex: 'id', key: 'id' },
    { title: 'Nome', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Telefone', dataIndex: 'phone', key: 'phone' },
    { title: 'Celular', dataIndex: 'cellphone', key: 'cellphone' },
    {
      title: 'Habilitado',
      dataIndex: 'isEnable',
      key: 'isEnable',
      render: (isEnable) => {
        return (
          <Switch checked={isEnable} disabled />
        );
      }
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space size="middle" wrap>
            <Button type="primary" size="small" onClick={() => onSelect(record)}>
              Editar
            </Button>
          </Space>
        )
      },
    }
  ];
  
  return (
    <>
      <Card>
        <Input.Search placeholder="Filtrar por nome" onSearch={onSearch} allowClear loading={isLoading} />
        <Table
          columns={columns}
          dataSource={sellers}
          rowKey="id"
          loading={isLoading}
          pagination={{ total: pagination.total, pageSize: pagination.limit, showSizeChanger: false, onChange: onPageChange }}
          locale={{ emptyText: 'Nenhum resultado encontrado' }}
        />
      </Card>
    </>
  );
};
