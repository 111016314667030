import { ProductTemplate } from '../../ProductTemplates/types/ProductTemplate';

export type PreviewProduct = {
  code: string;
  barcode: string;
  description: string;
  metricUnit: string;
}

const parseField = ({
  position,
  data,
}: {
  position: number | null;
  data: string[];
}) => {
  if (!position) return '';
  return (data[position - 1] || '').trim();
}

export const mapRow = (row: Record<string, any>, template: ProductTemplate) => {
  const data = Object.values(row);
  const product: PreviewProduct = {
    code: parseField({ position: template.codePosition, data }),
    barcode: parseField({ position: template.barcodePosition, data }),
    description: parseField({
      position: template.descriptionPosition,
      data,
    }),
    metricUnit: parseField({
      position: template.metricUnitPosition,
      data,
    }),
  };
  return product;
}