import React from 'react';
import { Layout, Result } from 'antd';

type Props = {
  title?: string;
  subtitle?: string;
}

export const NotFoundPage: React.FC<Props> = ({ title = '404', subtitle = 'Página não encontrada' }) => {
  
  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Layout.Content>
          <Result
            status="404"
            title={title}
            subTitle={subtitle}
          />
        </Layout.Content>
      </Layout>
    </>
  );
};
