import { create } from 'zustand';
import { useGlobalMessageStore } from '../../../components/GlobalMessageProvider/store';
import { Pagination } from '../../../types/Pagination';
import { BudgetItem, CreateBudgetItem } from '../types/BudgetItem';
import { getBudgetItems, createBudgetItem, deleteBudgetItem } from './services';

export type BudgetItemStore = {
  init: () => void;
  isCreateItemDrawerOpened: boolean;
  openCreateItemDrawer: () => void;
  closeCreateItemDrawer: () => void;
  budgetId: number | null;
  setBudgetId: (budgetId: number | null) => void;
  isLoading: boolean;
  budgetItems: BudgetItem[];
  budgetItemsPagination: Pagination;
  setPagination: (pagination: Partial<Pagination>) => void;
  getBudgetItems: ({ budgetId }: { budgetId: number }) => void;
  createBudgetItem: (item: CreateBudgetItem) => void;
  deleteBudgetItem: (budgetItemId: number) => void;
};

export const useBudgetItemStore = create<BudgetItemStore>()(
  (set, get) => {
    return {
      isCreateItemDrawerOpened: false,
      openCreateItemDrawer() {
        set({ isCreateItemDrawerOpened: true });
      },
      closeCreateItemDrawer() {
        set({ isCreateItemDrawerOpened: false });
      },
      isLoading: false,
      budgetItems: [],
      budgetItemsPagination: { page: 1, pages: 1, limit: 10, total: 1 },
      budgetId: null,
      setBudgetId(budgetId) {
        set({ budgetId });

        if (budgetId) {
          get().getBudgetItems({ budgetId });
        }
      },
      setPagination({ page, limit }) {
        const budgetId = get().budgetId;
        const budgetItemsPagination = get().budgetItemsPagination;
        if (page) set({ budgetItemsPagination: { ...budgetItemsPagination, page } });
        if (limit) set({ budgetItemsPagination: { ...budgetItemsPagination, limit } });

        if (budgetId) {
          get().getBudgetItems({ budgetId });
        }
      },
      async getBudgetItems({ budgetId }) {
        try {
          set({ isLoading: true });
          const { page, limit } = get().budgetItemsPagination;
          const response = await getBudgetItems({ page, limit, budgetId });
          const { data, ...pagination } = response;
          set({ budgetItems: data, budgetItemsPagination: pagination, isLoading: false });
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível buscar os itens do orçamento. Tente novamente em instantes.'
          });
        }
      },
      async createBudgetItem(item) {
        try {
          const budgetId = get().budgetId;
          if (budgetId) {
            set({ isLoading: true });
            await createBudgetItem(item, budgetId);
            set({ isLoading: false, isCreateItemDrawerOpened: false });
            useGlobalMessageStore.getState().successNotification({
              message: 'Item adicionado com sucesso ao orçamento'
            });
            get().getBudgetItems({ budgetId });
          }
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível criar o item. Tente novamente em instantes.'
          });
        }
      },
      async deleteBudgetItem(budgetItemId) {
        try {
          const budgetId = get().budgetId;
          if (budgetId) {
            set({ isLoading: true });
            await deleteBudgetItem(budgetId, budgetItemId);
            set({ isLoading: false });
            useGlobalMessageStore.getState().successNotification({
              message: 'Item removido com sucesso do orçamento'
            });
            get().getBudgetItems({ budgetId });
          }
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível criar o item. Tente novamente em instantes.'
          });
        }
      },
      init() {
        set({
          isLoading: false,
          budgetItems: [],
          budgetItemsPagination: { page: 1, pages: 1, limit: 10, total: 1 },
          budgetId: null,
        });
      },
    }
  }
);
