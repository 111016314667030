import React, { useMemo } from 'react';
import { MaskedInput } from 'antd-mask-input'
import { InputProps } from 'antd';

interface Props extends Omit<InputProps, 'value' | 'defaultValue'> {
  mask: string;
  value?: string | undefined;
  defaultValue?: string;
}

export const InputMask: React.FC<Props> = ({ value = '', onChange, mask, ...rest }) => {
  const { "aria-invalid": ariaInvalid } = rest;

  const status = useMemo(() => {
    return ariaInvalid ? 'error' : ''
  }, [ariaInvalid])

  const handleChange = (
    { unmaskedValue }: { unmaskedValue: string}
  ) => {
    const event = { target: { value: unmaskedValue } } as unknown as React.ChangeEvent<HTMLInputElement>
    if(onChange) {
      onChange(event);
    }
  }
  
  return (
    <MaskedInput
      status={status}
      mask={mask}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  );
};
