import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthStore } from '../Authentication/store';
import { BudgetAnswerDetails } from './BudgetAnswerDetails';
import { BudgetAnswerList } from './BudgetAnswerList';
import { useBudgetAnswerStore } from './store/budgetAnswerStore';
import { BudgetAnswerItem } from './types/BudgetAnswerItem';
import { Card, Tabs } from 'antd';
import { BudgetResultsList } from '../BudgetResults/components/BudgetResultsList';
import { useBudgetResultStore } from '../BudgetResults/store/budgetResultStore';
import { BudgetStatus } from '../Budget/types/BudgetStatus';

export const BudgetAnswerContainer: React.FC = () => {
  const { budgetAnswerId } = useParams<{ budgetAnswerId: string }>();
  const {
    currentBudgetAnswer,
    setCurrentBudgetAnswerId,
    budgetAnswerItems,
    isLoading,
    updateBudgetAnswerItem,
    setPagination,
    budgetAnswerItemsPagination,
    closeBudgetAnswer,
  } = useBudgetAnswerStore();

  const {
    isLoading: isLoadingResults,
    budgetResults,
    getBudgetResults,
    budgetResultsPagination,
    budgetResultsLoosers,
    getBudgetResultsLoosers,
    setPagination: setResultsPagination,
    setBudgetId,
  } = useBudgetResultStore();

  const { currentUser } = useAuthStore();

  useEffect(() => {
    if (budgetAnswerId) {
      setCurrentBudgetAnswerId(budgetAnswerId);
    }
  }, []);

  useEffect(() => {
    if (currentBudgetAnswer) {
      setBudgetId(currentBudgetAnswer.budgetId);
    }
  }, [currentBudgetAnswer, setBudgetId]);
  
  const handleOnUpdateItem = (item: BudgetAnswerItem) => {
    updateBudgetAnswerItem(item)
  }

  return (
    <>
      <BudgetAnswerDetails
        budgetAnswer={currentBudgetAnswer}
        currentUser={currentUser}
        closeBudgetAnswer={closeBudgetAnswer}
        budgetAnswerStatus={currentBudgetAnswer?.status}
      />
      <Card>
        <Tabs
          tabPosition="top"
          destroyInactiveTabPane
          defaultActiveKey='answers'
          items={[
            {
              label: 'Todas as respostas',
              key: 'answers',
              children: (
                <>
                {
                  currentBudgetAnswer?.budgetStatus !== BudgetStatus.closed ? (
                    <BudgetAnswerList
                      budgetAnswerItems={budgetAnswerItems}
                      onUpdateItem={handleOnUpdateItem}
                      isLoading={isLoading}
                      setPagination={(page) => setPagination({ page })}
                      pagination={budgetAnswerItemsPagination}
                      budgetAnswerStatus={currentBudgetAnswer?.status}
                      budgetAnswer={currentBudgetAnswer}
                    />
                  ) : (
                    <BudgetResultsList
                      isLoading={isLoadingResults}
                      budgetResults={budgetResults}
                      getBudgetResults={getBudgetResults}
                      pagination={budgetResultsPagination}
                      setPagination={setResultsPagination}
                      getBudgetResultsLoosers={getBudgetResultsLoosers}
                      budgetResultsLoosers={budgetResultsLoosers}
                      expandable={false}
                      sellerId={currentUser?.id}
                      key={currentUser?.id}
                      budgetId={currentBudgetAnswer?.budgetId || 0}
                      winnersOnly={false}
                    />
                  )
                }
                </>
              ),
            },
            {
              label: 'Respostas vencedoras',
              key: 'results',
              disabled: currentBudgetAnswer?.budgetStatus !== BudgetStatus.closed,
              children: (
                <BudgetResultsList
                  isLoading={isLoadingResults}
                  budgetResults={budgetResults}
                  getBudgetResults={getBudgetResults}
                  pagination={budgetResultsPagination}
                  setPagination={setResultsPagination}
                  getBudgetResultsLoosers={getBudgetResultsLoosers}
                  budgetResultsLoosers={budgetResultsLoosers}
                  expandable={false}
                  sellerId={currentUser?.id}
                  key={currentUser?.id}
                  budgetId={currentBudgetAnswer?.budgetId || 0}
                  winnersOnly
                />
              ),
            }
          ]}
        />
      </Card>
    </>
  );
}