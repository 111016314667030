import { AxiosError } from 'axios';
import { create } from 'zustand';
import { useGlobalMessageStore } from '../../../components/GlobalMessageProvider/store';
import { Pagination } from '../../../types/Pagination';
import { Budget } from '../types/Budget';
import { BudgetStatus } from '../types/BudgetStatus';
import { createBudget, getBudget, getBudgets, updateBudget, publish, close } from './services';

export type BudgetStore = {
  init: () => void;
  isLoading: boolean;
  getBudgetsError: boolean;
  getBudgetsSuccess: boolean;
  budgetsPagination: Pagination;
  budgets: Budget[],
  currentBudgetId: number | null;
  getBudgets: (
    { searchTerm, page, limit, status }:
      { searchTerm: string, page: number, limit: number, status: BudgetStatus[] }
  ) => Promise<void>;
  createSuccess: boolean;
  updateSuccess: boolean;
  createBudget: (budget: Budget) => Promise<void>;
  updateBudget: (budget: Budget) => Promise<void>;
  budget: Budget | null;
  getBudgetError: boolean;
  getBudget: (budgetId: number) => Promise<void>;
  resetSaveParams: () => void;
  budgetNotFound: boolean;
  publishBudget: () => void;
  closeBudget: () => void;
};

export const useBudgetStore = create<BudgetStore>()(
  (set, get) => {
    return {
      budgetNotFound: false,
      init() {
        set({
          isLoading: false,
          getBudgetsError: false,
          getBudgetsSuccess: false,
          budgetsPagination: { page: 1, pages: 1, limit: 10, total: 1 },
          budgets: [],
          createSuccess: false,
          updateSuccess: false,
          budget: null,
          currentBudgetId: null,
        })
      },
      resetSaveParams() {
        set({ createSuccess: false, updateSuccess: false });
      },
      isLoading: false,
      getBudgetsError: false,
      getBudgetsSuccess: false,
      budgetsPagination: { page: 1, pages: 1, limit: 10, total: 1 },
      budgets: [],
      currentBudgetId: null,
      async getBudgets({ searchTerm, page, limit, status }) {
        try {
          set({ isLoading: true, getBudgetError: false, getBudgetsSuccess: false });
          const response = await getBudgets({ searchTerm, limit, page, status });
          const { data, ...pagination } = response;
          set({
            isLoading: false,
            getBudgetsSuccess: true,
            budgets: data,
            budgetsPagination: pagination,
          });
        } catch (error) {
          console.error(error);
          set({
            isLoading: false,
            getBudgetsError: true,
          });
        }
      },
      createError: false,
      createSuccess: false,
      updateError: false,
      updateSuccess: false,
      async createBudget(budget) {
        try {
          set({ isLoading: true, createSuccess: false });
          const response = await createBudget(budget);
          set({ isLoading: false, createSuccess: true, currentBudgetId: response.id });
          useGlobalMessageStore.getState().successNotification({
            message: 'Orçamento criado com sucesso.'
          });
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível criar o orçamento. Tente novamente em instantes.'
          });
        }
      },
      async updateBudget(budget) {
        try {
          set({ isLoading: true, updateSuccess: false });
          const response = await updateBudget(budget);
          set({ isLoading: false, updateSuccess: true, budget: response });
          useGlobalMessageStore.getState().successNotification({
            message: 'Orçamento atualizado com sucesso.'
          });
        } catch (error) {
          console.error(error);
          set({ isLoading: false });
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível atualizar o orçamento. Tente novamente em instantes.'
          });
        }
      },
      budget: null,
      getBudgetError: false,
      async getBudget(budgetId) {
        try {
          set({ isLoading: true, getBudgetError: false, budgetNotFound: false });
          const budget = await getBudget({ budgetId });
          set({ isLoading: false, budget });
        } catch (error) {
          console.error(error);
          if (error instanceof AxiosError) {
            if (error.response?.status === 404) {
              set({ budgetNotFound: true, });
            }
          }
          set({ isLoading: false, getBudgetError: true });
        }
      },
      async publishBudget() {
        try {
          set({ isLoading: true });
          const currentBudget = get().budget;
          if(currentBudget?.id) {
            await publish(currentBudget.id);
            get().getBudget(currentBudget.id);
          }
          set({ isLoading: false });
          useGlobalMessageStore.getState().successNotification({
            message: 'Orçamento publicado com sucesso.'
          });
        } catch (error) {
          console.error(error);
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível publicar o orçamento.',
            description: 'Tente novamente em instantes.'
          });
          set({ isLoading: false });
        }
      },
      async closeBudget() {
        try {
          set({ isLoading: true });
          const currentBudget = get().budget;
          if(currentBudget?.id) {
            await close(currentBudget.id);
            get().getBudget(currentBudget.id);
          }
          set({ isLoading: false });
          useGlobalMessageStore.getState().successNotification({
            message: 'Orçamento encerrado com sucesso.'
          });
        } catch (error) {
          console.error(error);
          useGlobalMessageStore.getState().errorNotification({
            message: 'Não foi possível encerrar o orçamento.',
            description: 'Tente novamente em instantes.'
          });
          set({ isLoading: false });
        }
      },
    }
  }
);
