import React, { useEffect } from 'react';
import { Button, DatePicker, Form, Input, Row, Select, Space } from 'antd';
import { Budget } from '../types/Budget';
import { Seller } from '../../Seller/types/Seller';
import { BudgetStore } from '../store';
import locale from 'antd/es/date-picker/locale/pt_BR';
import dayjs from 'dayjs';
import { useCurrentPage } from '../../../hooks';
import { RangePickerProps } from 'antd/es/date-picker';
import { BudgetStatus } from '../types/BudgetStatus';

type Props = {
  budget: Budget | null;
  sellers: Seller[];
  isLoading: boolean;
  saveFunction: BudgetStore['createBudget'] | BudgetStore['updateBudget'];
}

export const BudgetForm: React.FC<Props> = ({
  budget,
  sellers,
  isLoading,
  saveFunction,
}) => {
  const [form] = Form.useForm<Budget>();
  const { navigate, subdomain } = useCurrentPage();

  const sellersOptions = sellers.map((seller) => ({
    label: seller.name,
    value: seller.id,
  }));

  useEffect(() => {
    form.resetFields();
    if (budget) {
      form.setFieldsValue(budget);
    }
  }, [budget, form]);

  const onFinish = (budget: Budget) => {
    saveFunction(budget);
  };

  const onClickToLeave = () => {
    navigate(`/${subdomain}/budget`)
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      requiredMark={false}
      layout="vertical"
      disabled={!!budget && budget?.status !== BudgetStatus.draft}
    >
      <Form.Item
        style={{ display: 'none' }}
        label="ID"
        name="id"
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Nome"
        name="name"
        rules={[{ required: true, message: 'Campo obrigatório' }]}
        wrapperCol={{ sm: 10, xs: 24 }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Descição"
        name="description"
        rules={[{ required: true, message: 'Campo obrigatório' }]}
        wrapperCol={{ sm: 10, xs: 24 }}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label="Vendedores"
        name="companySellerIds"
        wrapperCol={{ sm: 10, xs: 24 }}
        initialValue={[]}
      >
        <Select
          loading={isLoading}
          allowClear
          optionFilterProp="children"
          showSearch
          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Selecione pelo menos um vendedor"
          options={sellersOptions}
        />
      </Form.Item>
      <Form.Item
        label="Data de encerramento"
        name="expirationDate"
        wrapperCol={{ sm: 5, xs: 24 }}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
        getValueProps={(value) => ({ value: value ? dayjs(value): value })}
      >
        <DatePicker
          style={{ width: '100%' }}
          placeholder="Selecione uma data"
          format="DD/MM/YYYY"
          locale={locale}
          disabledDate={disabledDate}
        />
      </Form.Item>
      <Form.Item
        wrapperCol={{ sm: 10 }}
      >
        <Row style={{ paddingBottom: '5px', display: 'flex', justifyContent: 'flex-end' }}>
          <Space direction="horizontal" style={{ justifyContent: 'left' }}>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Salvar
            </Button>
            <Button block type="dashed" htmlType="submit" loading={isLoading} onClick={onClickToLeave}>
              Sair
            </Button>    
          </Space>
        </Row>
      </Form.Item>
    </Form>
  );
};