import React from 'react';
import { Outlet } from "react-router-dom";
import { createMenuItems } from '../helpers';
import { NavMenu } from './NavMenu';
import { useCurrentPage } from '../hooks';
import { Layout } from 'antd';
import { useAuthStore } from '../containers/Authentication/store';

export const MainLayout: React.FC = () => {
  const { currentPage, subdomain, navigate } = useCurrentPage()
  const { currentUser, logout } = useAuthStore();
  
  const onSetCurrentPage = (value: string) => {
    navigate(value);
  };

  const onMenuLogoClick = () => {
    navigate(`/${subdomain}`);
  };

  const onLogout = () => {
    logout(() => navigate(`/${subdomain}/login`));
  };
  
  const items = createMenuItems({ subdomain, userType: currentUser?.type, currentUser, });
  return (
    <Layout style={{ height: "100vh" }}>
      <Layout.Header style={{ backgroundColor: '#0E111E', paddingInline: 15 }}>
        <NavMenu
          items={items}
          setCurrentPage={onSetCurrentPage}
          currentPage={currentPage}
          onMenuLogoClick={onMenuLogoClick}
          onLogout={onLogout}
        />
      </Layout.Header>
      <Layout.Content>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};
