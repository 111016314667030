import React from 'react';
import { PageContent } from '../../components/PageContent';
import { PageHeader } from '../../components/PageHeader';
import { BudgetContainer } from '../../containers/Budget';

export const BudgetPage: React.FC = () => {
  return (
    <>
      <PageContent>
        <>
          <PageHeader
            title="Gestão de orçamentos"
            level={2}
            subtitle="Orçamentos da empresa"
          />
          <BudgetContainer />
        </>
      </PageContent>
    </>
  );
};