import { Button, Form, Input, notification, Switch } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';
import React, { useEffect } from 'react';
import { InputMask } from '../../components/InputMask';
import { Seller } from './types/Seller';

type Props = {
  seller: Seller | null;
  saveSeller: (seller: Seller) => void;
  saveError: boolean;
  saveSuccess: boolean;
  isLoading: boolean;
  notificationApi: NotificationInstance;
};

export const SellerForm: React.FC<Props> = ({ seller, saveSeller, isLoading, saveSuccess, saveError, notificationApi }) => {
  const [form] = Form.useForm<Seller>();

  useEffect(() => {
    if (seller) {
      form.resetFields();
      form.setFieldsValue(seller);
    }
  }, [])

  const onFinish = (seller: Seller) => {
    saveSeller(seller);
  };

  useEffect(() => {
    if (saveSuccess) {
      const message = seller ? 'Vendedor atualizado com sucesso!' : 'Vendedor criado com sucesso!';
      notificationApi.success({
        message,
        placement: 'bottomLeft',
      });

      if (!seller) {
        form.resetFields();
      }
    }
  }, [form, notificationApi, saveSuccess, seller]);

  useEffect(() => {
    if (saveError) {
      notificationApi.error({
        message: 'Não foi possível salvar o vendedor.',
        description: 'Tente novamente em instantes',
        placement: 'bottomLeft',
      })
    }
  }, [notificationApi, saveError]);

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        requiredMark={false}
        labelCol={{ span: 5 }}
      >
        <Form.Item
          style={{ display: 'none' }}
          label="ID"
          name="id"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Nome"
          name="name"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          wrapperCol={{ sm: 19 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          wrapperCol={{ sm: 19 }}
        >
          <Input type="mail" />
        </Form.Item>
        <Form.Item
          label="Telefone"
          name="phone"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          wrapperCol={{ sm: 7 }}
        >
          <InputMask mask="(00) 0000-0000" />
        </Form.Item>
        <Form.Item
          label="Celular"
          name="cellphone"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          wrapperCol={{ sm: 7 }}
        >
          <InputMask mask="(00) 00000-0000" />
        </Form.Item>
        <Form.Item
          label="Endereço"
          name="address"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          wrapperCol={{ sm: 19 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Habilitado"
          name="isEnable"
          wrapperCol={{ sm: 19 }}
          valuePropName="checked"
          initialValue={true}
        >
          <Switch />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 21, span: 3, sm: 3 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
