import React, { useEffect, useRef } from 'react';
import { useCurrentPage, useLocalStorage } from '../hooks';
import { useCompanyStore } from '../containers/Company/store';
import { LoadingPage } from './LoadingPage';
import { NotFoundPage } from './NotFoundPage';

type Props = {
  children: React.ReactElement;
};

const title = 'Empresa não encontrada';
const subtitle = 'Você pode ter digitado o domínio errado ou a empresa pode estar inativa';

export const SubdomainProvider: React.FC<Props> = ({ children }) => {
  const { setItem } = useLocalStorage();
  const { subdomain } = useCurrentPage();
  const subdomainRef = useRef<null | string>(null);

  const {
    getCompanyBySubdomain,
    isLoadingSubdomain,
    subdomainNotFound
  } = useCompanyStore();

  useEffect(() => {
    if (subdomain && !subdomainRef.current) {
      setItem('subdomain', subdomain);
      subdomainRef.current = subdomain;
    }
  }, [setItem, subdomain]);

  useEffect(() => {
    if (subdomainRef.current) {
      getCompanyBySubdomain(subdomainRef.current);
    }
  }, []);

  return (
    isLoadingSubdomain ? <LoadingPage /> : (
      subdomainNotFound ? <NotFoundPage title={title} subtitle={subtitle} /> : children
    )
  );
};