import { create } from 'zustand';
import { Pagination } from '../../../types/Pagination';
import { Seller } from '../types/Seller';
import { createSeller, getSellers, updateSeller } from './services';

export type SellerStore = {
  isLoading: boolean;
  getSellersError: boolean;
  getSellersSuccess: boolean;
  sellersPagination: Pagination;
  init: () => void;
  sellers: Seller[],
  getSellers: (
    { searchTerm, page, limit }: { searchTerm: string, page: number, limit: number }
  ) => Promise<void>;
  saveError: boolean;
  saveSuccess: boolean;
  saveSeller: (seller: Seller) => Promise<void>;
}

export const useSellerStore = create<SellerStore>()(
  (set) => {
    return {
      isLoading: false,
      getSellersError: false,
      getSellersSuccess: false,
      sellersPagination: { page: 1, pages: 1, limit: 10, total: 1 },
      init() {
        set({
          isLoading: false,
          getSellersError: false,
          getSellersSuccess: false,
          sellersPagination: { page: 1, pages: 1, limit: 10, total: 1 },
          saveError: false,
          saveSuccess: false,
        })
      },
      sellers: [],
      async getSellers({ searchTerm, page, limit }) {
        try {
          set({ isLoading: true, getSellersError: false, getSellersSuccess: false });
          const response = await getSellers({ searchTerm, page, limit });
          set({
            isLoading: false,
            getSellersError: false,
            getSellersSuccess: true,
            sellers: response.data,
            sellersPagination: { limit: response.limit, page: response.page, pages: response.pages, total: response.total }
          });
        } catch (error) {
          console.error(error);
          set({ isLoading: false, getSellersError: true, getSellersSuccess: false });
        }
      },
      saveError: false,
      saveSuccess: false,
      async saveSeller(seller) {
        try {
          set({ isLoading: true, saveError: false, saveSuccess: false });
          if (seller.id) {
            await updateSeller(seller);
          } else {
            await createSeller(seller);
          }
          set({ isLoading: false, saveSuccess: true });
        } catch (error) {
          console.error(error);
          set({ isLoading: false, saveError: true });
        }
      },
    }
  }
);