import React from 'react';
import { useAuthStore } from './store';
import { Button, Form, Input, Typography, Alert } from 'antd';
import { AuthRequest } from './store/services';
import { useCurrentPage } from '../../hooks';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';

export const ResetPasswordForm: React.FC = () => {
  const { passwordReset, loadingPasswordReset, passwordResetWasSent, isPasswordResetExpired, passwordResetFailed } = useAuthStore();
  const { subdomain, navigate } = useCurrentPage();
  const [form] = Form.useForm<AuthRequest>();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';

  
  const onFinish = async (values: { password: string }) => {
    passwordReset({ password: values.password, token })
  };

  const onLoginPageClick = (e: any) => {
    e.preventDefault();
    navigate(`/${subdomain}/login`);
  };

  const onForgotPageClick = (e: any) => {
    e.preventDefault();
    navigate(`/${subdomain}/forgot-password`);
  };

  const formItemStyle: React.CSSProperties = {
    marginBottom: '10px',
  };

  return (
    <>
      <Form onFinish={onFinish} form={form} layout="vertical" requiredMark={false} style={{ padding: '33px' }}>
        <Form.Item>
          <Typography.Title>
            Redefina a senha
          </Typography.Title>
          <Typography.Text>
            {
              passwordResetWasSent && (
                <Alert
                  message={(<><strong>Senha redefinida com sucesso!</strong> <a href="/" onClick={onLoginPageClick}>Entre agora mesmo</a></>)}
                  type="success"
                />
              )
            }
            {
              isPasswordResetExpired && (
                <Alert
                  message={(<><strong>O link de redefinição de senha expirou!</strong> <a href="/" onClick={onForgotPageClick}>Esqueceu sua senha?</a></>)}
                  type="error"
                />
              )
            }
            {
              passwordResetFailed && (
                <Alert
                  message={(<><strong>A redefinição de senha falhou!</strong> Estamos com um problema temporário. Tente daqui a pouco.</>)}
                  type="error"
                />
              )
            }
          </Typography.Text>
        </Form.Item>
        {
          (!passwordResetWasSent && !isPasswordResetExpired) && (
            <>
              <Form.Item
                name="password"
                label="Nova senha"
                rules={[{ required: true, message: 'Digite a nova senha!' }]}
                hasFeedback
                style={formItemStyle}
              >
                <Input.Password type="password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
              </Form.Item>
              <Form.Item
                name="passwordConfirm"
                label="Confirmar a nova senha"
                rules={[
                  { required: true, message: 'Confirme a nova senha!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) return Promise.resolve();
                      return Promise.reject(new Error('As duas senhas devem ser iguais!'));
                    }
                  })
                ]}
                dependencies={['password']}
                hasFeedback
                style={formItemStyle}
              >
                <Input.Password type="password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  block
                  style={{ marginTop: '5px' }}
                  htmlType="submit"
                  loading={loadingPasswordReset}
                >
                  Salvar nova senha
                </Button>
              </Form.Item>
            </>
          )
        }
        <Form.Item>
          Já tem uma conta? <a href="/" onClick={onLoginPageClick}>Entre agora mesmo</a>
        </Form.Item>
      </Form>
    </>
  )
};