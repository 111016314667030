import { Col, Empty, Input, Pagination, Row } from 'antd';
import React from 'react';
import { LoadingPage } from '../../components/LoadingPage';
import { Pagination as PaginationType } from '../../types/Pagination';
import { BudgetCard } from './components/BudgetCard';
import { Budget } from './types/Budget';

type Props = {
  budgets: Budget[];
  pagination: PaginationType;
  onPageChange: (page: number) => void;
  isLoading: boolean;
  onSearch: (value: string) => void;
}

export const BudgetList: React.FC<Props> = ({ budgets, pagination, onPageChange, isLoading, onSearch }) => {
  return (
    <>
      <Row style={{ paddingBottom: '10px' }}>
        <Input.Search placeholder="Filtrar por nome" onSearch={onSearch} allowClear loading={isLoading} />
      </Row>
      {
        !isLoading ? (
          <>
            {
              budgets.length ? (
                <>
                  <Row>
                    {
                      budgets.map((budget, idx) => (
                        <Col md={4} xs={24} key={idx} style={{ paddingRight: '10px', paddingBottom: '10px' }}>
                          <BudgetCard budget={budget} />
                        </Col>
                      ))
                    }
                  </Row>
                  <Row style={{ paddingBottom: '5px', display: 'flex', justifyContent: 'flex-end' }}>
                    <Pagination
                      total={pagination.total}
                      pageSize={pagination.limit}
                      showSizeChanger={false}
                      onChange={onPageChange}
                      current={pagination.page}
                    />
                  </Row>
                </>
              ) : (<Empty description="Nenhum orçamento encontrado" />)
            }
          </>
        ) : <LoadingPage />
      }
    </>
  );
}