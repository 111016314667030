import React from 'react';
import { Layout, Col, Row } from 'antd';
import { ResetPasswordForm } from '../../containers/Authentication'

export const ResetPasswordPage: React.FC = () => {
  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Layout.Content>
          <Row align="middle">
            <Col sm={6}>
              <ResetPasswordForm />
            </Col>
            <Col sm={18} style={{ textAlign: 'center', height: "100vh", backgroundColor: '#0E111E' }}>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </>
  );
};
