import React, { useEffect, useState } from 'react';
import { InputNumber } from 'antd';

type Props = {
  value: string | null;
  defaultValue: string;
  onChange: (value: number) => void;
  disabled: boolean;
}

export const InputBudgetAnswerSmallerQuantity: React.FC<Props> = ({ value, defaultValue, onChange, disabled }) => {
  const [initialValue, setInitialValue] = useState<number>(Number(value || defaultValue));
  const [currentValue, setCurrentValue] = useState<number>(Number(value || defaultValue));
  
  useEffect(() => {
    setInitialValue(Number(value || defaultValue));
  }, [defaultValue, value]);

  const handleOnBlur = () => {
    if (initialValue !== currentValue) {
      onChange(currentValue);
    }
  }

  const handleOnChange = (value: number | null) => {
    if(value) {
      setCurrentValue(value);
    }
  }
  return (
    <InputNumber
      disabled={disabled}
      size="small"
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      rootClassName="input-price"
      defaultValue={initialValue}
      max={Number(defaultValue)}
      decimalSeparator=","
      precision={2}
      controls={false}
      tabIndex={0}
    />
  );
}