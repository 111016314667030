import React from 'react';
import { PageContent } from '../../components/PageContent';
import { PageHeader } from '../../components/PageHeader';
import { CompanyContainer } from '../../containers/Company';

export const CompanyPage: React.FC = () => {
  return (
    <>
      <PageContent>
        <>
          <PageHeader
            title="Empresas"
            level={2}
            subtitle="Criação e edição de empresas"
          />
          <CompanyContainer />
        </>
      </PageContent>
    </>
  );
};