import React from 'react';
import { useAuthStore } from './store';
import { Button, Form, Input, Typography, Alert } from 'antd';
import { AuthRequest } from './store/services';
import { useCurrentPage } from '../../hooks';

export const ForgotPasswordForm: React.FC = () => {
  const { requestPasswordReset, loadingRequestPasswordReset, requestPasswordResetWasSent } = useAuthStore();
  const { subdomain, navigate } = useCurrentPage();
  const [form] = Form.useForm<AuthRequest>();
  
  const onFinish = async (values: { email: string }) => {
    requestPasswordReset({ email: values.email, subdomain })
  };

  const onForgotPageClick = (e: any) => {
    e.preventDefault();
    navigate(`/${subdomain}/login`);
  };

  const formItemStyle: React.CSSProperties = {
    marginBottom: '10px',
  };

  return (
    <>
      <Form onFinish={onFinish} form={form} layout="vertical" requiredMark={false} style={{ padding: '33px' }}>
        <Form.Item>
          <Typography.Title>
            Esqueceu sua senha?
          </Typography.Title>
          <Typography.Text>
            <strong>Informe seu endereço de e-mail e enviaremos um link para redefinir sua senha</strong>
            {requestPasswordResetWasSent && <Alert message={(<><strong>Link enviado!</strong> Caso exista um cadastro na empresa para este e-mail, <strong>enviaremos instruções para ele.</strong> Fique de olho na sua caixa de entrada!</>)} type="success" /> }
          </Typography.Text>
        </Form.Item>
        {
          !requestPasswordResetWasSent && (
            <>
              <Form.Item name="email" label="E-mail" rules={[{ type: 'email', required: true, message: 'Campo obrigatório' }]} style={formItemStyle}>
                <Input />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  block
                  style={{ marginTop: '5px' }}
                  htmlType="submit"
                  loading={loadingRequestPasswordReset}
                >
                  Enviar link
                </Button>
              </Form.Item>
            </>
          )
        }
        <Form.Item>
          Já tem uma conta? <a href="/" onClick={onForgotPageClick}>
            Entre agora mesmo
          </a>
        </Form.Item>
      </Form>
    </>
  )
};