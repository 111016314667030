import { useSearchParams } from 'react-router-dom';
import { BudgetStatus } from '../types/BudgetStatus';
import queryString from 'query-string';

type Filter = {
  status: BudgetStatus[];
}

export const useFilterParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const filters = queryString.parse(searchParams.toString(), { arrayFormat: 'bracket' }) as unknown as Filter;

  const setFilters = (filters: Partial<Filter>) => {
    Object.entries(filters).forEach(([key, value]: [string, any]) => {
      setSearchParams(queryString.stringify(filters, { arrayFormat: 'bracket' }));
    });
  };

  return {
    filters,
    setFilters,
  }
}