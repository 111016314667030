import React, { useEffect } from 'react';
import { useBudgetResultStore } from '../store/budgetResultStore';
import { BudgetResultsList } from './BudgetResultsList';
import { Card, Tabs } from 'antd';

type Props = {
  budgetId?: string;
}

export const BudgeResultsCheapestItemsBySellers: React.FC<Props> = ({ budgetId }) => {
  const {
    isLoading,
    getBudgetResults,
    budgetResults,
    budgetResultsPagination,
    setPagination,
    setBudgetId,
    getBudgetSellers,
    budgetSellers,
    getBudgetResultsLoosers,
    budgetResultsLoosers,
    currentBudgetId,
  } = useBudgetResultStore();

  useEffect(() => {
    setBudgetId(Number(budgetId));
  }, []);

  useEffect(() => {
    if (currentBudgetId) {
      getBudgetSellers();
    }
  }, [currentBudgetId, getBudgetSellers]);

  return (
    <Card>
      <Tabs
        tabPosition="left"
        defaultActiveKey="0"
        destroyInactiveTabPane
        items={[
          {
            label: 'Geral',
            key: '0',
            children: (
              <BudgetResultsList
                isLoading={isLoading}
                budgetResults={budgetResults}
                getBudgetResults={getBudgetResults}
                pagination={budgetResultsPagination}
                setPagination={setPagination}
                getBudgetResultsLoosers={getBudgetResultsLoosers}
                budgetResultsLoosers={budgetResultsLoosers}
                budgetId={currentBudgetId}
                key={0}
              />
            )
          },
          ...budgetSellers.map(seller => {
            return {
              label: seller.name,
              key: seller.email,
              children: (
                <BudgetResultsList
                  isLoading={isLoading}
                  budgetResults={budgetResults}
                  getBudgetResults={getBudgetResults}
                  pagination={budgetResultsPagination}
                  setPagination={setPagination}
                  getBudgetResultsLoosers={getBudgetResultsLoosers}
                  budgetResultsLoosers={budgetResultsLoosers}
                  expandable={false}
                  sellerId={seller.id}
                  key={seller.id}
                  budgetId={currentBudgetId}
                />
              )
            }
          }),
        ]}
      />
    </Card>
  );
};