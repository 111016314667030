import React, { useEffect, useMemo, useState } from 'react';
import { Popover, Table, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { BudgetItemResult } from '../types/BudgetItemResult';
import { BudgetResultStore } from '../store/budgetResultStore';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { BudgetResultListItem } from './BudgetResultListItem';

const { Text } = Typography;

type Props = {
  isLoading: boolean;
  getBudgetResults: BudgetResultStore['getBudgetResults'];
  getBudgetResultsLoosers: BudgetResultStore['getBudgetResultsLoosers'];
  budgetResults: BudgetResultStore['budgetResults'];
  budgetResultsLoosers: BudgetResultStore['budgetResultsLoosers'];
  pagination: BudgetResultStore['budgetResultsPagination'];
  setPagination: BudgetResultStore['setPagination'];
  expandable?: boolean;
  budgetItemId?: number;
  sellerId?: number;
  winnersOnly?: boolean;
  budgetId: number | null;
}

const addLooserChildrenToItem = (item: BudgetItemResult, loosers: BudgetItemResult[], expandable: Boolean): BudgetItemResult => {
  if(expandable) {
    if (!item.children) return { ...item, children: [] };
    if (!loosers.length) return { ...item, children: [] };
    if (item.budgetItemId === loosers[0].budgetItemId) {
      const children = loosers.map((item): BudgetItemResult => {
        return {
          id: item.id,
          budgetItemId: item.budgetItemId,
          budgetTotalPrice: "",
          description: "",
          metricUnit: "",
          observation: "",
          observations: item.observations,
          position: item.position,
          price: item.price,
          totalPrice: item.totalPrice,
          smallerQuantity: item.smallerQuantity || item.quantity,
          quantity: item.quantity,
          sellerName: item.sellerName,
          barcode: "",
          code: "",
        }
      })
      return { ...item, children };
    }
    if (!item.children) return { ...item, children: [] };
    return item;
  }
  return item;
}

export const BudgetResultsList: React.FC<Props> = ({
  isLoading,
  getBudgetResults,
  getBudgetResultsLoosers,
  budgetResults,
  pagination,
  setPagination,
  expandable = true,
  budgetItemId,
  budgetResultsLoosers,
  sellerId,
  winnersOnly,
  budgetId
}) => {
  const budgetResultsWithChildren = useMemo(() => budgetResults.map((item) => addLooserChildrenToItem(item, budgetResultsLoosers, expandable)), [budgetResults]);
  const [resultWinners, setResultWinners] = useState<BudgetResultStore['budgetResults']>(budgetResultsWithChildren);
  const total = useMemo(() => resultWinners.length ? resultWinners[0].budgetTotalPrice : 0, [resultWinners]);
  
  useEffect(() => {
    if (budgetId) {
      getBudgetResults({ sellerId, winnersOnly });
    }
  }, [budgetId, getBudgetResults, sellerId, winnersOnly]);

  useEffect(() => {
    setResultWinners((previous) => {
      return previous.map((item => addLooserChildrenToItem(item, budgetResultsLoosers, expandable)));
    });
  }, [budgetResultsLoosers, expandable]);
  

  useEffect(() => {
    setResultWinners(budgetResultsWithChildren);
  }, [budgetResultsWithChildren]);

  const columns: ColumnsType<BudgetItemResult> = [
    ...(expandable ? [{ title: '', dataIndex: 'expand', key: 'expand' }] : []),
    ...(winnersOnly === undefined ? [{ title: 'Vendedor', dataIndex: 'sellerName', key: 'sellerName' }] : []),
    { title: 'Código', dataIndex: 'code', key: 'code', responsive: ['md'] },
    { title: 'Cód. Barras', dataIndex: 'barcode', key: 'barcode', responsive: ['md'] },
    { title: 'Descrição', dataIndex: 'description', key: 'description', responsive: ['md'] },
    {
      title: 'Qtd. Orçada',
      dataIndex: 'quantity',
      key: 'quantity',
      responsive: ['md'],
      render(_, record) {
        if (!expandable) return record.quantity;
        return (record.position === 0) && record.quantity;
      },
    },
    {
      title: 'Qtd. disponível',
      dataIndex: 'smallerQuantity',
      key: 'smallerQuantity',
      responsive: ['md'],
      render(value, record, index) {
        if ((record.smallerQuantity && record.quantity) && Number(record.smallerQuantity) < Number(record.quantity)) {
          return (
            <Popover title="Quantidade disponível menor que a quantidade orçada">
              <Text type="warning" underline>
                <WarningOutlined /> {record.smallerQuantity || record.quantity}
              </Text>
            </Popover>
          )
        }
        return <Text type="success"><CheckCircleOutlined /> {record.smallerQuantity || record.quantity}</Text>
      },
    },
    { title: 'Unid. Medida', dataIndex: 'metricUnit', key: 'metricUnit', responsive: ['md'] },
    {
      title: 'Valor unitário',
      dataIndex: 'price',
      key: 'price',
      responsive: ['md'],
      render(value, record, index) {
        return value ? <Text type={record.position > 0 ? 'warning' : 'success'}>R${value}</Text> : <Text type="danger">S/R</Text>;
      },
    },
    {
      title: 'Valor total',
      dataIndex: 'totalPrice',
      key: 'price',
      responsive: ['md'],
      render(value, record, index) {
        return value ? <Text type={record.position > 0 ? 'warning' : 'success'}>R${value}</Text> : <Text type="danger">S/R</Text>;
      },
    },
    { title: 'Obs.', dataIndex: 'observation', key: 'observation', responsive: ['md'] },
    { title: 'Obs. Vendedor', dataIndex: 'observations', key: 'observations', responsive: ['md'] },
    {
      title: '',
      dataIndex: 'winner',
      key: 'winner',
      responsive: ['md'],
      render(value, record, index) {
        return (
          <>
            {record.position === 0 && <Tag color="green">Vencedor</Tag> }
          </>
        )
      },
    },
    {
      title: 'Itens',
      responsive: ['xs'],
      render(_, record) {
        return <BudgetResultListItem item={record} />;
      },
    }
  ]
  return (
    <Table
      size="small"
      loading={isLoading}
      dataSource={resultWinners}
      columns={columns}
      tableLayout="auto"
      locale={{ emptyText: 'Nenhum resultado encontrado' }}
      summary={(data) => {
        if (budgetItemId) return null;
        
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}><strong>Total itens: {pagination.total}</strong></Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} colSpan={2}>
                <Text type="success"><strong>Total: R${total}</strong></Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
      }}
      expandable={{
        indentSize: 0,
        onExpand(expanded, record) {
          if (expanded) {
            getBudgetResultsLoosers(record.budgetItemId);
          }
        },
      }}
      pagination={{
        total: pagination.total,
        pageSize: pagination.limit,
        position: ['bottomRight'],
        onChange: (page) => setPagination({ page }),
      }}
      rowKey="id"
    />
  );
}