import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Switch
} from 'antd';
import React, { useEffect } from 'react';
import { ProductTemplate } from './types/ProductTemplate';

type Props = {
  selectedTemplate: ProductTemplate | null;
  saveTemplate: (template: ProductTemplate) => void;
  isSaving: boolean;
  saveComplete: boolean;
  saveError: boolean;
}

export const ProductTemplateForm: React.FC<Props> = ({
  selectedTemplate,
  saveComplete,
  saveError,
  saveTemplate,
  isSaving
}) => {
  const [form] = Form.useForm<ProductTemplate>();
  const [notificationApi, contextHolder] = notification.useNotification();

  const onFinish = (productTemplate: ProductTemplate) => {
    saveTemplate(productTemplate);
  };

  useEffect(() => {
    if (selectedTemplate) {
      form.resetFields();
      form.setFieldsValue(selectedTemplate);
    }
  }, [])

  useEffect(() => {
    if (saveComplete) {
      const message = selectedTemplate ? 'Template atualizado com sucesso!' : 'Template criado com sucesso!';
      notificationApi.success({
        message,
        placement: 'bottomLeft',
      });

      if(!selectedTemplate) {
        form.resetFields();
      }
    }
  }, [form, notificationApi, saveComplete, selectedTemplate]);

  useEffect(() => {
    if (saveError) {
      notificationApi.error({
        message: 'Não foi possível salvar o template.',
        description: 'Tente novamente em instantes',
        placement: 'bottomLeft',
      })
    }
  }, [notificationApi, saveError]);

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        labelCol={{ span: 8 }}
        requiredMark={false}
      >
        <Form.Item
          style={{ display: 'none' }}
          label="ID"
          name="id"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Nome"
          name="name"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          wrapperCol={{ sm: 14 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Separador de coluna"
          name="separator"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          wrapperCol={{ sm: 4 }}
        >
          <Input maxLength={1} />
        </Form.Item>
        <Form.Item
          label="Separador de string"
          name="quote"
          wrapperCol={{ sm: 4 }}
        >
          <Input maxLength={1} />
        </Form.Item>
        <Form.Item
          label="Ignorar cabeçalho"
          name="ignoreHeader"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Divider orientation="left">
          Posição das colunas
        </Divider>
        <Row style={{ marginBottom: '10px' }}>
          <Col span={24}>
            <Alert message="O número da coluna é inciado por 1." type="info" showIcon />
          </Col>
        </Row>
        <Row>
          <Col span={6} sm={6} xs={24}>
            <Form.Item
              label="Código"
              name="codePosition"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 23 }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <InputNumber min={1} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={6} sm={6} xs={24}>
            <Form.Item
              label="Cód. de barras"
              name="barcodePosition"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 23 }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <InputNumber min={1} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={6} sm={6} xs={24}>
            <Form.Item
              label="Descrição"
              name="descriptionPosition"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 23 }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <InputNumber min={1} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={6} sm={6} xs={24}>
            <Form.Item
              label="Unid. Medida"
              name="metricUnitPosition"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 23 }}
            >
              <InputNumber min={1} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isSaving}>
                Salvar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}