import { Col, Row, Typography } from 'antd';
import React from 'react';

type Props = {
  children: React.ReactElement;
  backgoundColor?: string;
};

export const PageContent: React.FC<Props> = ({ children, backgoundColor }) => {
  return (
    <div style={{ padding: '10px', backgroundColor: backgoundColor, height: "100vh" }}>
      {children}
    </div>
  );
};
