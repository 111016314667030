import { RcFile } from 'antd/es/upload';
import { create } from 'zustand';
import { importProducts } from './services';

export type ProductImportStore = {
  loadingImport: boolean;
  errorImport: boolean;
  successImport: boolean;
  importFile: ({ templateId, file }: { templateId: number, file: RcFile }) => Promise<void>;
  init: () => void;
}

export const useProductImportStore = create<ProductImportStore>()(
  (set) => {
    return{
      loadingImport: false,
      errorImport: false,
      successImport: false,
      async importFile({ templateId, file }) {
        try {
          set({ loadingImport: true, errorImport: false, successImport: false });
          await importProducts({ templateId, file });
          set({ loadingImport: false, successImport: true });
        } catch (error) {
          console.log('aaa');
          set({ loadingImport: false, errorImport: true });
        }
      },
      init() {
        set({ loadingImport: false, errorImport: false, successImport: false });
      },
    }
  }
);