import React from 'react';
import { SellerContainer } from '../../containers/Seller';
import { PageContent } from '../../components/PageContent';
import { PageHeader } from '../../components/PageHeader';

export const SellerPage: React.FC = () => {
  return (
    <>
      <PageContent>
        <>
          <PageHeader
            title="Vendedores"
            level={2}
            subtitle="Criação e edição de vendedores parceiros"
          />
          <SellerContainer />
        </>
      </PageContent>
    </>
  );
};