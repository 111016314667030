import { Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useProductStore } from '../store';
import { useDebounce } from '../../../../hooks/useDebounce';
import { Product } from '../types/Product';

type Props = {
  ['aria-invalid']?: string;
  onSelect: (product: Product) => void;
}

export const SelectProduct: React.FC<Props> = (props) => {
  const {
    init,
    isLoading,
    products,
    setSearchTerm,
    setPagination,
  } = useProductStore();
  const [loacalSearchTerm, setLocalSearchTerm] = useState<string>('');

  const debouncedSearchTerm = useDebounce<string>(loacalSearchTerm, 500);

  useEffect(() => {
    init();
    setPagination({ limit: 100 }, { searchOnChange: false });
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearchTerm(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, setSearchTerm]);

  const options = useMemo(
    () => products.map((product) => {
      return { label: `${product.code} - ${product.description}`, value: product.id }
    }),
    [products],
  );

  const handleSearch = useCallback(
    (searchTerm: string) => setLocalSearchTerm(searchTerm),
    [],
  )

  const handleChange = (value: number) => {
    const product = products.find((p) => value === p.id);
    if (product) {
      props.onSelect(product);
    }
  };

  return (
    <Select
      status={props['aria-invalid'] && 'error'}
      loading={isLoading}
      filterOption={false}
      defaultActiveFirstOption={false}
      showSearch
      onSearch={handleSearch}
      onChange={handleChange}
      options={options}
      notFoundContent="Nenhum item encontrado"
    />
  );
};