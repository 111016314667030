import { httpClient } from '../../../helpers/httpClient';
import { GetUsersParams } from '../types/GetUsersParams';
import { PaginatedUser } from '../types/PaginatedUser';
import { SaveUserParams } from '../types/SaveUserParams';
import { User } from '../types/User';

const authClient = httpClient();

export const getUsers = async (data: GetUsersParams) => {
  if (!data.searchTerm) delete data.searchTerm;
  const params = new URLSearchParams(data as Record<string, any>);
  const response = await authClient().get<PaginatedUser>(`/user?${params.toString()}`);
  return response.data;
};

export const saveUser = async (user: SaveUserParams) => {
  await authClient().post<void>('/user', user);
};

export const updateUser = async (user: User) => {
  await authClient().put<void>('/user', user);
};