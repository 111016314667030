import { ClockCircleOutlined, EditOutlined, EllipsisOutlined, FundViewOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Divider, Dropdown, MenuProps, Space, Tag } from 'antd';
import Meta from 'antd/es/card/Meta';
import React from 'react';
import { formatToLocaleDate } from '../../../helpers';
import { useCurrentPage } from '../../../hooks';
import { Budget } from '../types/Budget';
import { BudgetStatusTag } from './BudgetStatusTag';
import { BudgetStatus } from '../types/BudgetStatus';

type Props = {
  budget: Budget;
};

export const BudgetCard: React.FC<Props> = ({ budget }) => {
  const { navigate } = useCurrentPage();

  const onClickEdit = () => {
    navigate(`${budget.id}/edit`);
  };

  const onClickResults = () => {
    navigate(`${budget.id}/results`);
  };

  const items: MenuProps['items'] = [
    {
      label: 'Ver resultados',
      key: '0',
      onClick: () => onClickResults(),
      disabled: budget.status === BudgetStatus.draft,
    },
  ];

  return (
    <Card
      actions={[
        <EditOutlined key="edit" onClick={onClickEdit} />,
        <Dropdown menu={{ items }}>
          <EllipsisOutlined key="results" />
        </Dropdown>,
      ]}
    >
      <BudgetStatusTag budgetStatus={budget.status} />
      <Meta
        title={budget.name}
        description={budget.id}
      />
      <Divider />
      <Space direction="vertical" style={{ display: 'flex', width: '100%' }} size={[200, 0]} >
        <span><ClockCircleOutlined /> Expira em: {formatToLocaleDate(budget.expirationDate)}</span>
        <span><UserOutlined /> Criado por: {budget.companyUser?.name}</span>
      </Space>
    </Card>
  );
};
