import React from 'react';
import { BudgetItemResult } from '../types/BudgetItemResult';
import { Descriptions, List, Space, Tag } from 'antd';

type Props = {
  item: BudgetItemResult;
};

export const BudgetResultListItem: React.FC<Props> = ({ item }) => {
  return (
    <List.Item>
      <Descriptions
        title={
          <Space direction="horizontal" wrap style={{ width: '100%', lineBreak: 'auto' }}>{item.code} - {item.description}</Space>
        }
      >
        <Descriptions.Item>
          {item.position === 0 && <Tag color="green">Vencedor</Tag>}
        </Descriptions.Item>
        {item.observation && <Descriptions.Item label="Obs. Cliente">{item.observation}</Descriptions.Item>}
        <Descriptions.Item label="Quantidade">{item.quantity}</Descriptions.Item>
        <Descriptions.Item label="Valor unitário">R$: {item.price}</Descriptions.Item>
        <Descriptions.Item label="Qtd. Disponpivel:">{item.smallerQuantity || item.quantity}</Descriptions.Item>
        {item.observations && <Descriptions.Item label="Obs. Suas:">{item.observations}</Descriptions.Item>}
      </Descriptions>
    </List.Item>
  );
};