import React, { useState } from 'react';
import { useAuthStore } from './store';
import { Button, Form, Input, Typography, Alert } from 'antd';
import { AuthRequest } from './store/services';
import { useCurrentPage } from '../../hooks';

export const LoginForm: React.FC = () => {
  const { authenticate, loadingAuthenticate } = useAuthStore();
  const [loginFailed, setLoginFailed] = useState<boolean>(false);
  const { subdomain, navigate } = useCurrentPage();
  const [form] = Form.useForm<AuthRequest>();
  
  const login = async (values: { email: string, password: string }) => {
    try {
      await authenticate({
        email: values.email,
        password: values.password,
        subdomain,
      });
      navigate(`/${subdomain}`);
    } catch (error) {
      setLoginFailed(true);
    }
  };

  const onForgotPageClick = (e: any) => {
    e.preventDefault();
    navigate(`/${subdomain}/forgot-password`);
  };

  const formItemStyle: React.CSSProperties = {
    marginBottom: '10px',
  };

  return (
    <>
      <Form onFinish={login} form={form} layout="vertical" requiredMark={false} style={{ padding: '33px' }}>
        <Form.Item>
          <Typography.Title>
            Bem-vindo(a)!
          </Typography.Title>
          <Typography.Text>
            <strong>Entre na plataforma com seu e-mail e senha cadastrados.</strong>
            {loginFailed && <Alert message="E-mail ou senha incorretos" type="error" /> }
          </Typography.Text>
        </Form.Item>
        <Form.Item name="email" label="E-mail" rules={[{ type: 'email', required: true, message: 'Campo obrigatório' }]} style={formItemStyle}>
          <Input />
        </Form.Item>
        <Form.Item name="password" label="Senha" rules={[{  required: true, message: 'Campo obrigatório' }]} style={formItemStyle}>
          <Input type="password" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            block
            style={{ marginTop: '5px' }}
            htmlType="submit"
            loading={loadingAuthenticate}
          >
            Entrar com e-mail cadastrado
          </Button>
        </Form.Item>
        <Form.Item>
          <a href="/" onClick={onForgotPageClick}>
            Esqueceu sua senha?
          </a>
        </Form.Item>
      </Form>
    </>
  )
};