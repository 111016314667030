import { BudgetStatus } from '../../Budget/types/BudgetStatus';

export enum BudgetAnswerStatus {
  draft = 'DRAFT',
  closed = 'CLOSED',
};

export type BudgetAnswer = {
  id?: string;
  budgetId: number;
  status: BudgetAnswerStatus;
  budgetStatus: BudgetStatus;
  name: string;
  description?: string;
  observations?: string;
};
