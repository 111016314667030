import queryString from 'query-string';
import { httpClient } from '../../../helpers/httpClient';
import { PaginatedData } from '../../../types/PaginatedData';
import { Seller } from '../../Seller/types/Seller';
import { BudgetItemResult } from '../types/BudgetItemResult';

const authClient = httpClient();

export const getBudgetItemsResults = async (
  { budgetId, page, limit, sellerId, winnersOnly }: { budgetId: number; page: number; limit: number; sellerId?: number, winnersOnly?: boolean }
) => {
  const params = queryString.stringify({ page, limit, sellerId, winnersOnly }, { arrayFormat: 'bracket' });
  const response = await authClient().get<PaginatedData<BudgetItemResult>>(`/budget/${budgetId}/result/item?${params}`)
  return response.data;
};

export const getBudgetItemsResultsLoosers = async (
  { budgetId, budgetItemId, page, limit }: { budgetId: number; budgetItemId: number; page: number; limit: number }
) => {
  const params = queryString.stringify({ page, budgetItemId, limit, loosersOnly: true, winnersOnly: false }, { arrayFormat: 'bracket' });
  const response = await authClient().get<PaginatedData<BudgetItemResult>>(`/budget/${budgetId}/result/item?${params}`)
  return response.data;
};

export const getBudgetSellers = async (
  { budgetId }: { budgetId: number }
) => {
  const response = await authClient().get<Seller[]>(`/budget/${budgetId}/seller`)
  return response.data;
};
