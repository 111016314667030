import { Alert, Button, Card, Descriptions, Popconfirm } from 'antd';
import React from 'react';
import { CurrentUser } from '../Authentication/store';
import { BudgetAnswer, BudgetAnswerStatus } from './types/BudgetAnswer';
import { BudgetStatus } from '../Budget/types/BudgetStatus';

type Props = {
  budgetAnswer: BudgetAnswer | null
  currentUser: CurrentUser | null
  closeBudgetAnswer: () => void;
  budgetAnswerStatus: BudgetAnswerStatus | undefined
}

export const BudgetAnswerDetails: React.FC<Props> = ({
  budgetAnswer,
  currentUser,
  closeBudgetAnswer,
  budgetAnswerStatus,
}) => {
  const disabledAnswer =
    budgetAnswerStatus !== BudgetAnswerStatus.draft || (
      budgetAnswerStatus === BudgetAnswerStatus.draft && budgetAnswer?.budgetStatus === BudgetStatus.closed
    )

  return (
    <Card
      extra={
        <Popconfirm
          placement="left"
          title="Tem certeza que deseja finalizar as respostas?"
          description="Depois de finalizado não será mais possível responder e a empresa receberá suas respostas."
          onConfirm={() => closeBudgetAnswer()}
          okText="Sim"
          cancelText="Cancelar"
          disabled={disabledAnswer}
        >
          <Button type="primary" size="large" title="Finalizar" disabled={disabledAnswer}>
            Finalizar
          </Button>
        </Popconfirm>
      }
    >
      {
        budgetAnswerStatus === BudgetAnswerStatus.draft && budgetAnswer?.budgetStatus === BudgetStatus.closed && (
          <Alert
            type="warning"
            showIcon
            message="Esse orçamento já está fechado e não pode mais ser respondido"
          />
        )
      }
      <Descriptions title={budgetAnswer?.name} layout="horizontal">
        <Descriptions.Item label="Empresa" style={{ paddingBottom: '0px' }}>
          {currentUser?.company.name}
        </Descriptions.Item>
        <Descriptions.Item label="Vendedor" style={{ paddingBottom: '0px' }}>
          {currentUser?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Observações" style={{ paddingBottom: '0px' }}>
          {budgetAnswer?.description}
        </Descriptions.Item>
      </Descriptions>
      {
        budgetAnswerStatus === BudgetAnswerStatus.closed && budgetAnswer?.budgetStatus === BudgetStatus.published && (
          <>
            <Alert
              type="success"
              showIcon
              message={<><strong>Agora é com a empresa!</strong> O orçamento foi respondido com sucesso.</>}
              closable
            />
            <Alert
              style={{ marginTop: '5px' }}
              type="info"
              showIcon
              message={<>Quando o orçamento for encerrado, você poderá ver as respostas vencedoras clicando na aba <strong>Respostas vencedoras</strong></>}
              closable
            />
          </>
        )
      }
    </Card>
  );
}