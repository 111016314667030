import { Tag } from 'antd';
import React from 'react';
import { BudgetStatus } from '../types/BudgetStatus';

type Props = {
  budgetStatus?: BudgetStatus;
};

const tagStatusColors = {
  [BudgetStatus.closed]: 'red',
  [BudgetStatus.draft]: '',
  [BudgetStatus.published]: 'blue',
};

const tagStatusText = {
  [BudgetStatus.closed]: 'Encerrado',
  [BudgetStatus.draft]: 'Rascunho',
  [BudgetStatus.published]: 'Publicado',
};

export const BudgetStatusTag: React.FC<Props> = ({ budgetStatus }) => {
  if (!budgetStatus) return null;
  return(
    <Tag color={tagStatusColors[budgetStatus]}>
      {tagStatusText[budgetStatus]}
    </Tag>
  );
};