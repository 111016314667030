import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Select, Form, Button, Popconfirm, notification } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useProductTemplateStore } from '../ProductTemplates/store';
import { FileUpload } from './FileUpload';
import { ProductTemplate } from '../ProductTemplates/types/ProductTemplate';
import { ImportOutlined } from '@ant-design/icons';
import { FilePreview } from './FilePreview';
import { LabeledValue } from 'antd/es/select';
import { useProductImportStore } from './store';

export const ProductImportContainer: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<RcFile | null>(null)
  const [selectedTemplate, setSelectedTemplate] = useState<ProductTemplate | null>(null)
  const [selected, setSelected] = useState<number | null>(null)
  const { templates, getTemplates, getTemplatesIsLoading } = useProductTemplateStore();
  const { importFile, init, loadingImport, errorImport, successImport } = useProductImportStore();
  const [notificationApi, contextHolder] = notification.useNotification();

  const options = templates.map((template) => {
    return {
      label: template.name,
      value: template.id,
    } as LabeledValue;
  })

  useEffect(() => {
    init();
    getTemplates({ page: 1, limit: 500, searchTerm: '' });
    return () => {
      init();
    }
  }, []);

  useEffect(() => {
    if (successImport) {
      notificationApi.success({
        message: 'Importação realizada com sucesso!',
        placement: 'bottomLeft',
      });

      setSelected(null);
      setSelectedFile(null);
      setSelectedTemplate(null);
    }
  }, [successImport, notificationApi]);

  useEffect(() => {
    if (errorImport) {
      notificationApi.error({
        message: 'Não foi possível completar a importação',
        description: 'Tente novamente em instantes',
        placement: 'bottomLeft',
      });
    }
  }, [errorImport, notificationApi]);

  const onSelectFile = (file: RcFile | null) => {
    setSelectedFile(file)
  }

  const onChangeTemplate = (value: number) => {
    setSelected(value)
    setSelectedTemplate(templates.find(t => t.id === value) || null)
  }

  const onSave = () => {
    if (selectedTemplate?.id && selectedFile) {
      importFile({ templateId: selectedTemplate.id, file: selectedFile })
    }
  };

  return (
    <>
      {contextHolder}
      <Row>
        <Col span={24} sm={24}>
          <Card>
            <Row>
              <Col span={24}>
                <Form layout="vertical">
                  <Form.Item
                    wrapperCol={{ span: 8, sm: 8 }}
                    label="Template"
                  >
                    <Select
                      loading={getTemplatesIsLoading}
                      showSearch
                      placeholder="Selecione um template de importação"
                      onChange={onChangeTemplate}
                      value={selected}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                      }
                      options={options}
                    />
                  </Form.Item>
                  <Form.Item wrapperCol={{ span: 24, sm: 24 }}>
                    <FileUpload
                      onSelectFile={onSelectFile}
                      disabled={!selectedTemplate}
                    />
                  </Form.Item>
                  <Form.Item wrapperCol={{ span: 24, sm: 24 }}>
                    <FilePreview
                      file={selectedFile}
                      template={selectedTemplate}
                    />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 21, span: 3, sm: 3 }}>
                    <Popconfirm
                      placement="leftBottom"
                      title="Tem certeza que quer importar?"
                      description="Essa ação pode sobrescrever produtos que já estejam importados. Tenha certeza que selecionou o template correto!"
                      onConfirm={onSave}
                      okText="Importar"
                      cancelText="Cancelar"
                    >
                      <Button
                        block
                        type="primary"
                        icon={<ImportOutlined />}
                        disabled={!selectedFile || !selectedTemplate}
                        loading={loadingImport}
                      >
                        Importar
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}